import { Flashbar } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import { IsItDownBanner } from './shared';

interface ServiceNotificationsProps {
  items: any[]
}

const ServiceNotifications = (props: RouteComponentProps<ServiceNotificationsProps>) => {
  const [items, setItems] = useState<any[]>([]);
  const { notifications, setNotifications } = useAppContext();

  useEffect(() => {
    setItems(notifications);
  }, [notifications, items]);

  return (
    <div
        data-testid={"flashbar-container"}
    >
      <IsItDownBanner />
      <Flashbar
        items={items.map((item) => ({ ...item, onDismiss: () => setNotifications([]) }))}
      />
    </div>
  );
};

export default withRouter(ServiceNotifications);
