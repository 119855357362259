import {colorChartsStatusHigh} from "@amzn/awsui-design-tokens";

export const alertIconSvg =

    <span>
        <svg
            version="1.1"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 17 17"
            fill={colorChartsStatusHigh}>
            <path
                d="M16.4,15.1l-7-14C9,0.3,8,0.3,7.6,1.1l-7,14c-0.3,0.7,0.2,1.4,0.9,1.4h14C16.2,16.5,16.7,15.7,16.4,15.1zM3.1,14.5L8.5,3.7l5.4,10.8H3.1z M9.5,11.5v2h-2v-2H9.5z M7.5,10.5v-4h2v4H7.5z"/>
        </svg>
    </span>