import { SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react';
import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DASHBOARD_PAGE_LINK, DASHBOARD_PAGE_NAME } from '../core/constants';
import { DarkModeToggle } from './shared';

const ServiceNavigation = (props: RouteComponentProps) => {
  const [activeHref, setActiveHref] = useState(
    `${props.location.pathname}`
  );

  const onFollowHandler = (event: any) => {
    event.preventDefault();
    if (event.detail.href) {
      props.history.push(event.detail.href);
      setActiveHref(event.detail.href);
    }
  }

  const buildNavigation = (): Array<SideNavigationProps.Item> => {
    let links: SideNavigationProps.Item[] = [
      { type: 'link', text: DASHBOARD_PAGE_NAME, href: DASHBOARD_PAGE_LINK },
      { type: 'divider' },
      {
        type: "link",
        text: '',
        href: '',
        info: <DarkModeToggle />
      }
    ];
    return links;
  }

  return (
    <SideNavigation
      header={{ text: 'Engagement Security Portal (Beta)', href: '/' }}
      items={buildNavigation()}
      activeHref={activeHref}
      onFollow={onFollowHandler}
    />
  );
};

export default withRouter(ServiceNavigation);