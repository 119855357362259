import { Box, FormField, Input, InputProps } from "@amzn/awsui-components-react";
import { BaseChangeDetail } from "@amzn/awsui-components-react/polaris/input/interfaces";

/*
    SupportOrderInput provides validation on an input component in
    regards to the support order field of a resolver group member.
    (i.e. values 1-99)
*/

export interface SupportOrderInputProps extends InputProps {
    id: string;
    index: number;
    disabled: boolean;
    onChangeNotifyAttrs: (value: number | undefined, index: number) => void;
}

export const SupportOrderInput = (props: SupportOrderInputProps) => {

    function valueChanged(detail: BaseChangeDetail) {
        let validExp: RegExp = new RegExp('^([0-9]{1})$|^([1-9][0-9]?)$|^(0[1-9]{1})$');
        if (validExp.test(detail.value)) {
            props.onChangeNotifyAttrs(parseInt(detail.value), props.index);
        } else if (detail.value === '') {
            props.onChangeNotifyAttrs(undefined, props.index);
        }
    }

    return (
        <Box>
            <FormField
                description='Order of contact, 0 is highest priority.'
                label='Support Order'
            >
                <Input
                    id={props.id}
                    value={props.value}
                    onChange={({ detail }) => valueChanged(detail)}
                    placeholder="Assign between 0 and 99"
                    disabled={props.disabled}
                />
            </FormField>
        </Box>
    );
}