import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ServiceAppLayout from '../../ts/components/ServiceAppLayout';
import { EngagementDashboard } from '../components/engagement-dashboard';
import { Engagement, UserEngagement } from '../core/types';
import { useAppContext } from '../AppContext';
import { User } from '../core/user';

const EngagementDashboardPage = (props: RouteComponentProps) => {
  const [managerEngagements, setManagerEngagements] = useState<Engagement[] | undefined>(undefined);
  const [resourceEngagements, setResourceEngagements] = useState<Engagement[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState(new User());
  const { setNotifications, client } = useAppContext();

  useEffect(() => {
    loadData()
      .then(() => setLoading(false))
      .catch(() => {
        setNotifications([{
          header: 'Failed to load engagements',
          type: 'error',
          dismissible: true,
          dismissLabel: 'Dismiss message',
        }]);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async (refresh?: boolean) => {
    if (managerEngagements === undefined || resourceEngagements === undefined || refresh) {
      const loadedEngagements: UserEngagement = await client.get(`/ts/engagements`) as UserEngagement;
      setManagerEngagements(loadedEngagements.managedEngagements);
      setResourceEngagements(loadedEngagements.engagements.filter((engagement) => engagement.manager !== user.userId));
    }
  }

  return (
    <ServiceAppLayout
      data-testid='engagement-dashboard-page'
      page='EngagementDashboardPage'
      content={<EngagementDashboard {...{ managerEngagements: managerEngagements || [], resourceEngagements: resourceEngagements || [], loading, loadData: loadData}}></EngagementDashboard>}
    />
  );
}

export default withRouter(EngagementDashboardPage);
