import { StatusIndicator } from '@amzn/awsui-components-react';
import { EngagementStatus } from '../../../core/types';
import { getEvaluatedInProgressStatus } from '../../../helpers/utils';
import './EngagementStatusLabel.css';


export const EngagementStatusLabel = (props) => (
    getStatusLabel(getEvaluatedInProgressStatus(props.engagement))
)

const getStatusLabel = (status) => {
    switch (status) {
        case EngagementStatus.IN_PROGRESS:
        case EngagementStatus.PLANNED:
            return <StatusIndicator className='status-indicator'colorOverride='green'>{status || 'Unknown'}</StatusIndicator>
        case EngagementStatus.STALLED:
        case EngagementStatus.ON_HOLD:
        case EngagementStatus.MISSING_PAPERWORK:
            return <StatusIndicator className='orange-status status-indicator'>{status || 'Unknown'}</StatusIndicator>
        case EngagementStatus.COMPLETED:
        case EngagementStatus.CANCELED:
        case EngagementStatus.CLOSED_DO_NOT_SURVEY:
        default:
            return <StatusIndicator className='status-indicator' colorOverride='grey'>{status || 'Unknown'}</StatusIndicator>
    }
}