import { Modal, Button, SpaceBetween, Box } from '@amzn/awsui-components-react';
import { AnalyticsEventType } from '../../../core/constants';

export interface ConfirmationModalProps {
    title?: string;
    message?: string;
    analyticsAttributes?: Record<string, any>;
    visible: boolean;
    loading: boolean;
    confirmAction: () => void;
    cancelAction: () => void;
    setVisibility: (visible: boolean) => void;
}
export const ConfirmationModal = (props: ConfirmationModalProps) => {
    return (
        <Modal
            onDismiss={() => props.setVisibility(false)}
            visible={props.visible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button data-testid='modalCancelButton' variant="link" disabled={props.loading} onClick={() => props.cancelAction()}>Cancel</Button>
                        <Button data-testid='modalConfirmButton'
                            variant="primary"
                            loading={props.loading}
                            onClick={() => props.confirmAction()}
                            data-csm-on={props.analyticsAttributes ? 'click' : undefined}
                            data-csm-name={props.analyticsAttributes ? AnalyticsEventType.ButtonClick : undefined}
                            data-csm-attrs={props.analyticsAttributes ? Object.entries(props.analyticsAttributes)
                                .map(([name, value]) => `${name}:${value}`)
                                .join(",") : undefined}
                        >Confirm</Button>
                    </SpaceBetween>
                </Box>
            }
            header={props.title || 'Confirm Action'}
        >
            {props.message || 'Are you sure you want to do this?'}
        </Modal>
    );
}