import { Box, Popover, StatusIndicator } from '@amzn/awsui-components-react';
import kebabCase from 'lodash/kebabCase';
import { UNDEFINED_VALUE } from '../../../core/constants';

export const ValueWithLabel = (props) => (
    <div data-testid={`${kebabCase(props.label)}`}>
        <Box margin={{ bottom: 'n' }} color='text-label'>
            <div className='label' data-testid='label'>{`${props.label} `}</div>
            {props.tooltip &&
                <Popover
                    dismissAriaLabel='Close'
                    size='medium'
                    triggerType='text'
                    content={props.tooltip}
                >
                    <StatusIndicator type='info' />
                </Popover>}
        </Box>
        {(props.value && <div className='value' data-testid='value'>{props.children || props.value}</div>) || UNDEFINED_VALUE}
    </div>
);
