import { AmplifyConfig } from './core/types';

const domain = window.location.host;
const isLocal: boolean = domain === "localhost:3000";
const protocol = isLocal ? "http" : "https";

let devWebConfig = {};
try {
  if (isLocal) devWebConfig = require("./dev-website-config").default;
} catch (e) {
  /**
   * Purposefully logging error instead of throwing it. "dev-website-config.ts" is ONLY needed for local development.
   * This try/catch statement acts as a conditional import for "dev-website-config.ts". Without it when `npm run build`
   * is ran in `/frontend` during the build phase of the CI/CD pipeline it will present the below error message and stop
   * the build:
   * ```
   *  Failed to compile.
   *
   *  ./src/ts/amplifyConfig.ts
   *  Cannot find file './dev-website-config' in './src/ts'.
   * ```
   * By logging the error, the build is able to complete and the error will be caught during runtime if devWebConfig is
   * attempted to be used, which will NEVER happen outside of your local machine (aka laptop). In other
   * words, THIS WILL NOT IMPACT the application during runtime if it is deployed/running from your developer account or
   * the production account.
   */
  console.log(
      "You are seeing this warning because `dev-website-config.ts` has not yet been created. \n" +
      "Please run `create-dev-web-config.ts` which can be found under `/frontend/scripts`."
  );
  console.warn(e);
}

const config: AmplifyConfig = {
  region: isLocal ? devWebConfig["region"] : window["epConfig"].region,
  identityPoolRegion: isLocal ? devWebConfig["region"] : window["epConfig"].region,
  userPoolId: isLocal ? devWebConfig["userPoolId"] : window["epConfig"].userPoolId,
  userPoolWebClientId: isLocal ? devWebConfig["userPoolWebClientId"] : window["epConfig"].userPoolWebClientId,
  identityPoolId: isLocal ? devWebConfig["identityPoolId"] : window["epConfig"].identityPoolId,
  pinpointId: isLocal ? devWebConfig["pinpointId"] : window['epConfig'].pinpointId,
  mandatorySignIn: false,
  oauth: {
    domain: isLocal ? devWebConfig["cognitoDomain"] : window['epConfig'].cognitoDomain,
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: `${protocol}://${domain}`,
    redirectSignOut: `${protocol}://${domain}`,
    responseType: "code"
  }
}

export { config };
