//React
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Polaris
import { Link, LinkProps } from '@amzn/awsui-components-react';

export const InternalLink = withRouter((props: RouteComponentProps<LinkProps>) => {
    const onFollowHandler = (ev) => {
        ev.preventDefault();
        if (ev.detail.href) {
            props.history.push(ev.detail.href);
        }
    };

    return (<Link id={props.id} className={props.className} color={props.color} href={props.href} variant={props.variant} onFollow={onFollowHandler}>{props.children}</Link>)
});