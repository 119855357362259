import { useCallback, useMemo } from 'react'
import { Analytics, Auth } from 'aws-amplify';
import { User } from '../user'
import { config } from '../../amplifyConfig';
import { AnalyticsEventType } from '../constants';

export function RecordEvent(props) {
  Analytics.record(props)
}

export function useAmplifyAnalytics() {

  const user = useMemo(() => {
    return new User()
  }, [])

  const initialize = useCallback(() => {
    Auth.configure({
      Auth: config
    })

    Analytics.configure({
      autoSessionRecord: true,
      mandatorySignin: true,
      AWSPinpoint: {
        appId: config.pinpointId,
        region: config.region || 'us-east-1',
        endpoint: {
          userId: user.userId,
          userAttributes: {
            groups: user.groups,
            user_alias: [user.userId]
          }
        }
      }
    });
    Analytics.autoTrack('pageView', {
      enable: true,
      eventName: AnalyticsEventType.View,
      attributes: () => ({
        name: 'page-view',
        page: getCurrentPage(window.location.pathname)
      }),
      type: 'SPA',
      provider: 'AWSPinpoint',
      getUrl: () => `${window.location.origin}${window.location.pathname}`,
    });
    Analytics.autoTrack('event', {
      enable: true,
      events: ['click'],
      selectorPrefix: 'data-csm-',
      provider: 'AWSPinpoint',
      attributes: {
        user_alias: user.userId
      }
    })
    Analytics.enable();
  }, [])

  const record = useCallback((props) => {
    RecordEvent(props)
  }, [])

  const getCurrentPage = (path: string) => {
    if (path.match(/^\/engagements\/?$/)) {
      return 'DashboardPage';
    } else if (path.match(/^\/engagements\/[A-Za-z0-9-]+\/?$/)) {
      return 'DetailPage';
    } else {
      return 'UnknownPage';
    }
  }

  return {
    initialize,
    record
  }
}