import {Box, SpaceBetween} from '@amzn/awsui-components-react';
import {Engagement} from '../../core/types';
import {EngagementsTable} from './tables';
import {useEffect, useMemo, useState} from 'react';
import {EngagementTableFilter, FilterPredicate} from './tables/TableFilter';
import {UserType} from '../../core/types/user';
import {DASHBOARD_PAGE_TITLE} from '../../core/constants';
import {EngagementSort} from '../../core/utils/engagement-utils/Sorting';
import {useAppContext} from '../../AppContext';


export interface EngagementDashboardProps {
  managerEngagements: Engagement[];
  resourceEngagements: Engagement[];
  loadData: (refresh?: boolean) => void;
  loading: boolean;
}

export const EngagementDashboard = (props: EngagementDashboardProps) => {
  const {
      seeAllEngagementsManagerToggle,
      seeAllEngagementsResourceToggle,
      setSeeAllEngagementsManagerToggle,
      setSeeAllEngagementsResourceToggle,
      featureFlags
  } = useAppContext();

  const [managerFilter, setManagerFilter] = useState<EngagementTableFilter>(
      new EngagementTableFilter(seeAllEngagementsManagerToggle ? FilterPredicate.All : FilterPredicate.Active)
  );
  const [resourceFilter, setResourceFilter] = useState<EngagementTableFilter>(
      new EngagementTableFilter(seeAllEngagementsResourceToggle ? FilterPredicate.All : FilterPredicate.Active)
  );
  const sorter = useMemo(() => new EngagementSort(), []);

  /**
   * TODO: Remove this useEffect once EHI Active feature has been approved.
   * [Flag - ff-frontend-ehi-active-20230221]
   * As soon as the feature flags are available, update the manager and resource filters to use the appropriate active
   * filter based on the ehi active feature flag.
   */
  useEffect(() => {
    setManagerFilter(new EngagementTableFilter(
        seeAllEngagementsManagerToggle ? FilterPredicate.All : FilterPredicate.Active,
        featureFlags["ff-frontend-ehi-active-20230221"]?.enabled as boolean
    ));
    setResourceFilter(new EngagementTableFilter(
       seeAllEngagementsResourceToggle ? FilterPredicate.All : FilterPredicate.Active,
        featureFlags["ff-frontend-ehi-active-20230221"]?.enabled as boolean
    ));
  }, [featureFlags]);

  // Filter managed and resource engagements with currently set filter
  // (only if something has changed)
  const filteredManagedEngagements: Engagement[] = useMemo(
      () => (
          props.managerEngagements.filter(managerFilter.filterPredicate)
      ),
      [managerFilter, props.managerEngagements]
  );
  const filteredResourceEngagements: Engagement[] = useMemo(
      () => (
          props.resourceEngagements.filter(resourceFilter.filterPredicate)
      ),
      [resourceFilter, props.resourceEngagements]
  );

  // TODO: Remove the ternary operators once EHI Active feature has been approved.
  // [Flag - ff-frontend-ehi-active-20230221]
  // Sort engagements by default sorting - re-sort whenever engagements change
  const sortedManagedEngagements: Engagement[] = useMemo(() => {
    return filteredManagedEngagements.sort(featureFlags["ff-frontend-ehi-active-20230221"]?.enabled
        ? sorter.sortEngagementsEhiActiveFFEnabled
        : sorter.sortEngagements
    );
  }, [filteredManagedEngagements]);
  const sortedResourceEngagements: Engagement[] = useMemo(() => {
    return filteredResourceEngagements.sort(featureFlags["ff-frontend-ehi-active-20230221"]?.enabled
        ? sorter.sortEngagementsEhiActiveFFEnabled
        : sorter.sortEngagements
    )
  }, [filteredResourceEngagements]);

  /**
   * This function was made to fit the generic 'FilterToggle' class as a callback.
   *
   * @param newFilter - New filter to apply to the specified type
   * @param type - If no type is specified, the filter will not be applied
   */
  function applyFilter(newFilter: EngagementTableFilter, type?: UserType) {
    if (type) {
      if (type === UserType.MANAGER) {
        setSeeAllEngagementsManagerToggle(!seeAllEngagementsManagerToggle);
        setManagerFilter(newFilter);
      } else if (type === UserType.RESOURCE) {
        setSeeAllEngagementsResourceToggle(!seeAllEngagementsResourceToggle);
        setResourceFilter(newFilter);
      }
    }
  }

  return (
    <div>
      <SpaceBetween
        direction='vertical'
        size='l'
      >
        <Box variant='h1'>{DASHBOARD_PAGE_TITLE}</Box>
        <Box>
          <SpaceBetween size='l' direction='vertical'>
            <EngagementsTable {...{
              items: sortedManagedEngagements,
              loading: props.loading,
              filter: applyFilter,
              tableType: UserType.MANAGER,
              filterToggleChecked: seeAllEngagementsManagerToggle,
              loadData: props.loadData
              }} />
            <EngagementsTable {...{
              items: sortedResourceEngagements,
              loading: props.loading,
              filter: applyFilter,
              tableType: UserType.RESOURCE,
              filterToggleChecked: seeAllEngagementsResourceToggle,
              loadData: props.loadData
            }} />
          </SpaceBetween>
        </Box >
      </SpaceBetween >
    </div >
  );
};
