import { useInterval } from '../../../core/hooks';
import { useAppContext } from '../../../AppContext';
import {Workflows} from "../../../core/workflows";
import {WorkflowStatus} from "../../../core/types/WorkflowStatus"
import { Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import {useState} from "react";
import {useEngagementDetailPageContext} from "../../../pages/EngagementDetailPageContext";
import {
    RG_MEMBER_OPERATIONS_FAILED,
    RG_MEMBER_OPERATIONS_IN_PROGRESS,
    RG_MEMBER_OPERATIONS_SUCCEEDED, RG_MEMBER_OPERATIONS_TIMED_OUT
} from "../../../core/constants";
import {UpdateRgOperations} from "../../../core/types/WorkflowsInFlight";

export type QueryProps = {
    engagementId: string;
    refreshResources: () => void;
}

export const QueryWorkflowsBanners = (props: QueryProps) => {
    const {workflowPollTimeout, endWorkflowPolling, resetWorkflowPollTimeout} = useEngagementDetailPageContext()
    const [bannerItems, setBannerItems] = useState<FlashbarProps.MessageDefinition[]>([]);
    const [completedOperations, setCompletedOperations] = useState<UpdateRgOperations>({})
    const { client } = useAppContext();

    const workflows = new Workflows(props.engagementId);

    // edit all banner items only if there is a change
    const editBannerItems = (items: FlashbarProps.MessageDefinition[]) => {
        let hasChanged = false;
        for(const item of items) {
            const banner = bannerItems.find((value) => value.id === item.id);
            if(!banner || JSON.stringify(banner) !== JSON.stringify(item)){
                hasChanged = true;
                break;
            }
        }
        if(hasChanged){
            setBannerItems(items)
        }
    }

    // function to be called on timeout of the poller
    const cleanup = () => {
        let banners : FlashbarProps.MessageDefinition[] = []
        // if there are still workflows in sessionStorage, set the timeout banner
        if(workflows.workflows.length !== 0) {
            banners.push({
                type: "warning",
                content: RG_MEMBER_OPERATIONS_TIMED_OUT,
                id: 'timeout-banner',
                dismissible: true,
                onDismiss: () => {
                    // clear banners and run logic again to rebuild other banners
                    setBannerItems([])
                    resetWorkflowPollTimeout()
                }
            });
        }
        // remove the workflows from storage, they have timed out from querying
        workflows.workflows.forEach((workflow) => {
            workflows.removeWorkflow(workflow.workflow_id)
        })
        buildBanners(banners)
    }
    const buildBanners = (banners: FlashbarProps.MessageDefinition[] = []) => {
        // if there are still workflows in flight
        if (workflows.totalAdd || workflows.totalRemove || workflows.totalModify) {
            // set up the in-progress banner
            banners.push({
                type: 'info',
                content: RG_MEMBER_OPERATIONS_IN_PROGRESS(workflows.totalAdd, workflows.totalRemove, workflows.totalModify),
                id: 'in-progress-banner',
                loading: true,
                dismissible: true,
                onDismiss: () => {
                    // clear banners, wipe in-progress workflows, and run logic again to rebuild other banners
                    setBannerItems([])
                    workflows.workflows.forEach((workflow) => workflows.removeWorkflow(workflow.workflow_id))
                    resetWorkflowPollTimeout()
                }
            })
        }
        // if there are succeeded workflows
        if (completedOperations.addSucceeded || completedOperations.removeSucceeded || completedOperations.modifySucceeded) {
            // set up the success banner
            banners.push({
                type: 'success',
                content: RG_MEMBER_OPERATIONS_SUCCEEDED(completedOperations.addSucceeded, completedOperations.removeSucceeded, completedOperations.modifySucceeded),
                id: 'success-banner',
                dismissible: true,
                onDismiss: () => {
                    // clear banners, wipe all succeeded workflows, refresh members table, and run logic again to rebuild other banners
                    setBannerItems([])
                    setCompletedOperations({
                    ...completedOperations,
                    addSucceeded: 0,
                    removeSucceeded: 0,
                    modifySucceeded: 0,
                })
                    props.refreshResources()
                    resetWorkflowPollTimeout()
                }
            })
        }
        // if there are failed workflows
        if (completedOperations.addFailed || completedOperations.removeFailed || completedOperations.modifyFailed) {
            // set up the failure banner
            banners.push({
                type: 'error',
                content: RG_MEMBER_OPERATIONS_FAILED(completedOperations.addFailed, completedOperations.removeFailed, completedOperations.modifyFailed),
                id: 'failure-banner',
                dismissible: true,
                onDismiss: () => {
                    // clear banners, wipe all failed workflows, and run logic again to rebuild other banners
                    setBannerItems([])
                    setCompletedOperations({
                        ...completedOperations,
                        addFailed: 0,
                        removeFailed: 0,
                        modifyFailed: 0,
                    })
                    resetWorkflowPollTimeout()
                }
            })
        }
        // change the banners one time
        editBannerItems(banners)
    }
    // Polls for workflow queries every 5 seconds
    useInterval(() => {
        // if it is still within our querying window
        if (workflows.workflows.length !== 0) {
            // loop through all our saved workflows in sessionStorage
            workflows.workflows.forEach((workflow) => {
                // query GET workflows endpoint
                const url = `/ts/workflows/${workflow.workflow_id}`
                client.axios.get(url).then((response) => {
                    // if we reached an end state
                    if (response.data !== WorkflowStatus.Running) {
                        // remove the workflow from storage, it's done with querying
                        workflows.removeWorkflow(workflow.workflow_id)
                        if (response.data === WorkflowStatus.Succeeded) {
                            // add the operations to the current state
                            setCompletedOperations({
                                ...completedOperations,
                                addSucceeded: completedOperations.addSucceeded ? completedOperations.addSucceeded + workflow.add : workflow.add,
                                removeSucceeded: completedOperations.removeSucceeded ? completedOperations.removeSucceeded + workflow.remove : workflow.remove,
                                modifySucceeded: completedOperations.modifySucceeded ? completedOperations.modifySucceeded + workflow.modify : workflow.modify,
                            })
                        } else {
                            // add the operations to the current state
                            setCompletedOperations({
                                ...completedOperations,
                                addFailed: completedOperations.addFailed ? completedOperations.addFailed + workflow.add : workflow.add,
                                removeFailed: completedOperations.removeFailed ? completedOperations.removeFailed + workflow.remove : workflow.remove,
                                modifyFailed: completedOperations.modifyFailed ? completedOperations.modifyFailed + workflow.modify : workflow.modify,
                            })
                        }
                    }
                })
                .catch((error) => {
                    // catch errors with getting the workflow status, end polling and show the timeout banner
                    console.error(error)
                });
            })
        } else {
            // if the workflows in storage have all been polled, artificially "time out" the poller to stop polling
            endWorkflowPolling()
        }
        buildBanners()
    }, 5000, workflowPollTimeout, cleanup );

    return (<Flashbar items={bannerItems} />)
}