import { useState } from "react";
import { useAppContext } from "../../AppContext";

import { Container, Input } from "@amzn/awsui-components-react";

import { BaseKeyDetail, NonCancelableCustomEvent } from "@amzn/awsui-components-react/polaris/internal/events";
import { BaseChangeDetail } from "@amzn/awsui-components-react/polaris/input/interfaces";
import { Engagement } from "../../core/types";


export const SearchBar = (): JSX.Element => {
    const [ value, setValue] = useState("");
    const { client, setNotifications } = useAppContext();

    const onChange = (event: NonCancelableCustomEvent<BaseChangeDetail>) => {
        setValue(event.detail.value);
    };

    const onKeydown = async (event: NonCancelableCustomEvent<BaseKeyDetail>) => {
        if (event.detail.keyCode !== 13) return; // 13 is the key code for the "return" or "enter" key.

        const engagementFound = await searchForEngagement(value);
        if (engagementFound) {
            setNotifications([{
                type: "info",
                header: "Your search returns 1 matching engagement(s). Redirecting you now."
            }]);
            const url = `${window.location.origin}/engagements/${value}`;
            window.open(url, "_self");
        } else {
            setNotifications([{
                type: "info",
                header: "Your search returns 0 matching engagement(s).",
                dismissible: true,
                dismissLabel: "Dismiss message"
            }]);
        }
    };

    const searchForEngagement = async (projectId: string): Promise<Boolean> => {
        setNotifications([{
            type: "info",
            loading: true,
            header: `Searching for engagement with projectId: ${projectId}.`
        }]);
        let engagementFound: boolean;
        try {
            await client.get(`/ts/engagements/${value}`) as Engagement;
            engagementFound = true;
        } catch (e) {
            engagementFound = false;
        }
        return engagementFound;
    };

    return (
        <Container
            data-testid={"search-bar-container"}
            >
            <Input
                type={"search"}
                inputMode={"search"}
                placeholder={"Search for an engagement (PR-######)"}
                disableBrowserAutocorrect={false}
                autoComplete={false}
                spellcheck={false}
                value={value}
                onChange={onChange}
                onKeyDown={onKeydown}
            />
        </Container>
    );
};
