import { useMemo, useState } from 'react';
import { Alert, Box, Button, Grid, Modal, SpaceBetween } from "@amzn/awsui-components-react";
import { RGMember } from "../../../core/types/RGMember";
import { EPDisabledInput } from '../../shared/ep-disabled-input'
import { SupportOrderInput } from '../../shared/support-order-input';
import { DataAccessService } from '../../../core/api';
import structuredClone from '@ungap/structured-clone'
import { AnalyticsEvent, AnalyticsEventType, RG_MEMBER_EDIT_FLASHBAR_TEXT } from '../../../core/constants';
import { UpdateResolverGroupRequest, UpdateRgMemberDTO } from "../../../core/types/UpdateResolverGroupRequest";
import { AxiosError } from "axios";
import { useAppContext } from '../../../AppContext';
import { EngagementDetailPageContextProps, useEngagementDetailPageContext } from '../../../pages/EngagementDetailPageContext';
import { Workflows } from "../../../core/workflows";
import { StartWorkflowResponse } from "../../../core/types/StartWorkflowResponse";

export interface EditSupportOrderProps {
    loading: boolean;
    setLoading: (loading: boolean) => void;
    resourcesToEdit: RGMember[];
    visible: boolean;
    segment?: string;
    engagementId: string;
    projectId: string;
    resolverGroup: string;
    getRgData: () => RGMember[];
    setEditModalVisible: (visible: boolean) => void;
    refreshResources: () => void;
    displayRequestError: (errorMessage: string) => void;
}

export const EditSupportOrderModal = (props: EditSupportOrderProps) => {

    function updateMember(value: number | undefined, index: number) {
        let temp: RGMember[] = [...editedMembers];
        temp[index].support_order = value;
        setEditedMembers(temp);
    }

    async function sendEditsToESAC() {
        // Validate data
        for (let member of editedMembers) {
            if (member.support_order === undefined) {
                setError(true);
                return;
            }
        }

        // Reset error state after validation
        setError(false);

        const updatedMembers: UpdateRgMemberDTO[] = editedMembers.map(member => ({
            alias: member.alias,
            supportOrder: member.support_order,
        }))


        props.setLoading(true);
        // Edit members request
        const requestBody: UpdateResolverGroupRequest = {
            engagementId: props.engagementId,
            name: props.resolverGroup,
            updatedMembers,
        };

        try {
            const workflowId = await client.patch(`/ts/rg`, requestBody) as StartWorkflowResponse;
            if (featureFlags["workflow_status"]?.enabled) {
                Workflows.addWorkflow(props.engagementId, workflowId.workflow_id, {modify: updatedMembers.length})
                resetWorkflowPollTimeout()
            } else {
                setNotifications([{
                    header: 'Success',
                    content: RG_MEMBER_EDIT_FLASHBAR_TEXT(updatedMembers.length),
                    type: 'success',
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                }]);
                await props.refreshResources();
            }
        } catch (e) {
            const error = e as AxiosError;
            props.displayRequestError(error.message);
        }

        props.setLoading(false);
        props.setEditModalVisible(false);
    }

    function setModalVisible(visible: boolean) {
        if (!visible) {
            setEditedMembers(structuredClone(props.resourcesToEdit));
        }
        props.setEditModalVisible(visible);
    }

    // State variables
    const [error, setError] = useState<boolean>(false);
    const [client, setClient] = useState<DataAccessService>(new DataAccessService());
    const [editedMembers, setEditedMembers] = useState<RGMember[]>(structuredClone(props.resourcesToEdit));
    const [fullNames, setFullNames] = useState<Map<string, string>>(new Map<string, string>());
    const { resetWorkflowPollTimeout } = useEngagementDetailPageContext()
    const { featureFlags } = useAppContext();

    const { setNotifications } = useAppContext();

    // Parent changed selected, update child here
    if (editedMembers.length !== props.resourcesToEdit.length) {
        setEditedMembers(structuredClone(props.resourcesToEdit));
    }

    // Create map for easy access to special role full names
    let engagement: EngagementDetailPageContextProps = useEngagementDetailPageContext();
    useMemo(() => {
        let newNames: Map<string, string> = new Map<string, string>();
        newNames.set(engagement.engagement.manager, engagement.engagement.managerFullName);
        if (engagement.engagement.deliveryPracticeManager) {
            newNames.set(engagement.engagement.deliveryPracticeManager, engagement.engagement.dpmFullName as string);
        }
        if (engagement.engagement.securityBarRaiser) {
            newNames.set(engagement.engagement.securityBarRaiser, engagement.engagement.sbrFullName as string);
        }
        if (engagement.engagement.opportunityOwner) {
            newNames.set(engagement.engagement.opportunityOwner, engagement.engagement.opportunityOwnerFullName as string);
        }

        setFullNames(newNames);
    }, [engagement]);

    return (
        <Modal
            visible={props.visible}
            onDismiss={() => setModalVisible(false)}
            closeAriaLabel="Close window"
            size="medium"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button disabled={props.loading} onClick={(event) => setModalVisible(false)} variant="link">Cancel</Button>
                        <Button
                            loading={props.loading}
                            onClick={(event) => sendEditsToESAC()}
                            variant="primary"
                            data-csm-on='click'
                            data-csm-name={AnalyticsEventType.ButtonClick}
                            data-csm-attrs={`engagement_id:${props.projectId},segment:${props.segment},element_type:${AnalyticsEvent.UpdateResourcesButton}`}
                        >
                            Apply Changes
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Edit Support Order on Resources">
            <Alert visible={error} header="Error" type="error">
                Data fields cannot be empty.
            </Alert>
            {
                editedMembers.map((value, index, array) => {
                    let supportOrderId: string = `editSOInput${index}`;
                    let disabledInputId: string = `disabledInput${index}`;

                    let specialRoleName: string | undefined = fullNames.get(value.alias);
                    let nameDisplay: string = (specialRoleName || value.fullName) ? `${specialRoleName || value.fullName}, ${value.alias}` : value.alias;

                    return (
                        <Grid id={`${index}`} gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                            <EPDisabledInput id={disabledInputId} value={nameDisplay} label="Resource" secondaryLabel='Team member to modify the support order for.' />
                            <SupportOrderInput
                                id={supportOrderId}
                                index={index}
                                onChangeNotifyAttrs={updateMember}
                                value={value.support_order !== undefined ? value.support_order.toString() : ""}
                                disabled={props.loading} />
                        </Grid>
                    )
                })
            }
        </Modal>
    )
}