import { useEffect, useRef } from 'react';

/**
 * Custom Interval Hook from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param {Function} callback - Function to be called on interval
 * @param {number} delay - Interval delay in milliseconds
 * @param {Function} cleanup - Function to be called upon cleanup
 * @param {number} timeout - Timeout expiration time in milliseconds
 */
export function useInterval(callback: Function, delay: number, timeout?: number, cleanup: Function = () => {}) {
    const savedCallback = useRef<any>();
    const hasCleanedUp = useRef<boolean>(false);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        // if delay is not null, and if timeout is set and not passed
        if (delay !== null && (!timeout || timeout > Date.now())) {
            hasCleanedUp.current = false;
            tick();
            let id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            }
        } else if (timeout && timeout <= Date.now() && !hasCleanedUp.current) {
            hasCleanedUp.current = true;
            let id = setInterval(tick, delay);
            return () => {
                cleanup()
                clearInterval(id);
            }
        }
    }, [delay, timeout, cleanup]);
}