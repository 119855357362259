import {SessionStorage} from '../storage';
import {WorkflowInfo, WorkflowsInFlight} from "../types/WorkflowsInFlight";

const DEFAULT_WORKFLOWS_EXPIRATION_HOURS = 1;

function defaultWorkflowInFlight(): WorkflowsInFlight {
    return {
        workflows: [],
        expiration_time: 0,
        totalAdd: 0,
        totalRemove: 0,
        totalModify: 0,
    }
}

export type WorkflowsProps = {
    add?: number,
    remove?: number,
    modify?: number,
}

export default class Workflows {
    private workflowsStorage: WorkflowsInFlight;
    private readonly key: string;

    constructor (key) {
        this.key = key;
        this.workflowsStorage = SessionStorage.get(key) || defaultWorkflowInFlight();
    }

    get workflows() {
        return this.workflowsStorage.workflows
    }

    get totalAdd() {
        return this.workflowsStorage.totalAdd
    }

    get totalRemove() {
        return this.workflowsStorage.totalRemove
    }

    get totalModify() {
        return this.workflowsStorage.totalModify
    }

    clearExpired() {
        if (Date.now() > this.workflowsStorage.expiration_time) {
            SessionStorage.remove(this.key)
            this.workflowsStorage = defaultWorkflowInFlight()
        }
    }

    static addWorkflow(engagementId: string, workflowId: string, props: WorkflowsProps){
        const workflows = new Workflows(engagementId)
        workflows.addWorkflowInfo({
            workflow_id: workflowId,
            add: props.add ? props.add : 0,
            remove: props.remove ? props.remove : 0,
            modify: props.modify ? props.modify : 0,
        })
    }

    private addWorkflowInfo(info: WorkflowInfo) {
        // add the WorkflowInfo to the array
        this.workflowsStorage.workflows.push(info)
        this.workflowsStorage.totalAdd += info.add
        this.workflowsStorage.totalRemove += info.remove
        this.workflowsStorage.totalModify += info.modify

        // update or create the expiration timestamp
        let timestamp = new Date()
        timestamp.setUTCHours(timestamp.getUTCHours() + DEFAULT_WORKFLOWS_EXPIRATION_HOURS)
        this.workflowsStorage.expiration_time = timestamp.getTime()

        SessionStorage.set(this.key, this.workflowsStorage)
    }

    removeWorkflow(workflowId: string) {
        const workflows = this.workflowsStorage.workflows

        // find the index of the item to remove
        const removeIndex = workflows.findIndex((workflow) => {
            return workflow.workflow_id === workflowId
        })
        if (removeIndex !== -1){
            // remove the item and its data from the totals
            this.workflowsStorage.totalAdd -= workflows[removeIndex].add
            this.workflowsStorage.totalRemove -= workflows[removeIndex].remove
            this.workflowsStorage.totalModify -= workflows[removeIndex].modify
            this.workflowsStorage.workflows.splice(removeIndex, 1)
        }

        // if the array is empty, remove the item and return
        if (workflows.length === 0) {
            SessionStorage.remove(this.key)
            this.workflowsStorage = defaultWorkflowInFlight()
            return
        }

        // save the updated set of workflows
        SessionStorage.set(this.key, this.workflowsStorage)
    }
}
