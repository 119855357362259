import { Toggle, Box, SpaceBetween, BoxProps } from "@amzn/awsui-components-react";
import { UserType } from "../../../core/types/user";


/**
 * TableFilter
 *
 * predicate - A condition expression that evaluates to a boolean value.
 *
 * filterPredicate - Determines what entries are displayed in a table based on provided predicate. If no predicate
 * is provided it defaults to using one that will display all entries in a table.
 */
export class TableFilter {
    filterPredicate: (obj?: any) => boolean;

    constructor(filterPredicate?: (obj?: any) => boolean) {
        this.filterPredicate = filterPredicate ?? ((obj: any) => { return true; });
    }
}

export interface TableFilterToggleProps {
    displayOnText: string;
    displayOffText: string;
    onFilter: TableFilter;
    offFilter: TableFilter;
    filter: (newFilter: TableFilter, type?: UserType) => void;
    tableType?: UserType;
    checked: boolean;
    padding?: BoxProps.SpacingSize | BoxProps.Spacing | undefined
    id: string;
}

export const TableFilterToggle = (props: TableFilterToggleProps) => {

    return (
        <Box padding={props.padding}>
            <SpaceBetween direction="horizontal" size="xxs">
                <Toggle
                    id={props.id}
                    onChange={({ detail }) => {
                        if (detail.checked) {
                            props.filter(props.onFilter, props.tableType);
                        } else {
                            props.filter(props.offFilter, props.tableType);
                        }
                    } }
                    checked={props.checked}
                />
                <Box>
                    {props.checked ? props.displayOnText : props.displayOffText}
                </Box>
            </SpaceBetween>
        </Box>
    );
};
