//React
import { forwardRef, useImperativeHandle, useState } from 'react';

import { Select } from "@amzn/awsui-components-react";
import { getTimeZones } from '@vvo/tzdb';

export interface TimezoneSelectorProps {
    zone: string;
}

export const TimezoneSelector = forwardRef((props: TimezoneSelectorProps, _ref) => {
    const timezones = getTimeZones().map((zone) => ({ label: zone.currentTimeFormat, value: zone.name, filteringTags: [zone.abbreviation, zone.countryCode, zone.countryName, zone.alternativeName] }));
    const [
        selectedOption,
        setSelectedOption
    ] = useState(props.zone ? getZoneByValue(props.zone, timezones) : timezones[0]);

    useImperativeHandle(_ref, () => selectedOption.value);

    return (
        <Select
            selectedOption={selectedOption}
            onChange={({ detail }) =>
                setSelectedOption({ label: detail.selectedOption.label as string, value: detail.selectedOption.value as string, filteringTags: detail.selectedOption.filteringTags as string[] })
            }
            options={timezones}
            filteringType="auto"
            selectedAriaLabel="Selected"
        />
    );
});

export function getZoneByValue(value: string, zones: any[]) {
    return zones.find((zone) => zone.value === value);
}