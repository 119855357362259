import {
  Box,
  Header,
} from '@amzn/awsui-components-react';
import { useAppContext } from '../../../AppContext';
import * as TableConstants from '../../../core/constants';
import { Engagement } from '../../../core/types';
import { UserType } from '../../../core/types/user';
import { User } from '../../../core/user';
import { ExpandableTable, SuitcaseIcon } from '../../shared';
import { TableFilter, TableFilterToggle } from '../../shared/filter-toggle';
import './EngagementsTable.css';
import {EngagementTableFilter, FilterPredicate} from './TableFilter';
import {getRgLink, ticketHeader} from "../../../helpers/tickets";
import {ExpandedEngagementDetail} from "../../engagement-detail/ExpandedEngagementDetail";
import {EngagementsRow} from "./EngagementRow";


export interface EngagementsTableProps {
  items: Engagement[];
  loading: boolean;
  tableType: UserType;
  filterToggleChecked: boolean;
  filter: (newFilter: EngagementTableFilter, type?: UserType) => void;
  loadData: (refresh?: boolean) => void;
}

export const EngagementsTable = (props: EngagementsTableProps) => {
  const { client, featureFlags } = useAppContext();

  let user: User = new User();
  let header: string = TableConstants.RESOURCE_ENGAGEMENTS_TABLE_TITLE;
  let headerDescription: string = TableConstants.RESOURCE_ENGAGEMENTS_TABLE_DESCRIPTION;
  let tableId = 'resource-engagement-table';
  let toggleId = 'resource-table-filter-toggle';

  if (props.tableType === UserType.MANAGER) {
    header = TableConstants.MANAGED_ENGAGEMENTS_TABLE_TITLE;
    headerDescription = TableConstants.MANAGED_ENGAGEMENTS_TABLE_DESCRIPTION;
    tableId = 'manager-engagement-table';
    toggleId = 'manager-table-filter-toggle';
  }

  const confirmDeletion = async () => {
    // Refresh page
    await props.loadData(true);
  }

  return (
    <ExpandableTable
      id={tableId}
      data-testid={tableId}
      header={
        <div>
          <Header description={headerDescription} counter={`(${props.items.length})`}>
            {header}
          </Header>
          <TableFilterToggle
            id={toggleId}
            checked={props.filterToggleChecked}
            // TODO: TODO: Remove the ternary operators once EHI Active feature has been approved.
            // [Flag - ff-frontend-ehi-active-20230221]
            displayOnText={featureFlags["ff-frontend-ehi-active-20230221"]?.enabled
                ? TableConstants.ENGAGEMENTS_TABLE_TOGGLE_ON_TEXT_FF
                : TableConstants.ENGAGEMENTS_TABLE_TOGGLE_ON_TEXT
          }
            displayOffText={featureFlags["ff-frontend-ehi-active-20230221"]?.enabled
                ? TableConstants.ENGAGEMENTS_TABLE_TOGGLE_OFF_TEXT_FF
                : TableConstants.ENGAGEMENTS_TABLE_TOGGLE_OFF_TEXT
          }
            filter={props.filter}
            offFilter={ new EngagementTableFilter(
                    FilterPredicate.Active,
                    featureFlags["ff-frontend-ehi-active-20230221"]?.enabled as boolean
                )}
            onFilter={ new EngagementTableFilter(FilterPredicate.All) }
            tableType={props.tableType}
            padding={{ top: "s", bottom: 's', right: 's' }}
          />
        </div>
      }
      className='engagements-table'
      loading={props.loading}
      loadingText={TableConstants.ENGAGEMENTS_TABLE_LOADING_TEXT}
      items={props.items}
      empty={
        <Box textAlign="center" color="inherit">
          No engagements
        </Box>
      }
      getRowIcon={(engagement: Engagement) => <SuitcaseIcon />}
      getHeaderContent={ (engagement: Engagement) => EngagementsRow({engagement: engagement, user: user, client: client, confirmDeletion: confirmDeletion, loadData: props.loadData})}
      getExpandedContent={async (engagement: Engagement) => (ExpandedEngagementDetail({engagement: engagement}))}
    />
  );
};
