import { createContext, useContext } from "react";
import { Engagement } from "../core/types";

export interface EngagementDetailPageContextProps {
    engagement: Engagement;
    setEngagement: (engagement: Engagement) => void;
    workflowPollTimeout: number;
    resetWorkflowPollTimeout: () => void;
    endWorkflowPolling: () => void;
}

export const EngagementDetailPageContext = createContext<EngagementDetailPageContextProps>({
    engagement: {
        id: '',
        projectId: '',
        sfdcId: '',
        manager: '',
        managerFullName: '',
        managerReportsTo: '',
        name: '',
        customerName: '',
        customerId: '',
        startDate: '',
        endDate: '',
        city: '',
        region: '',
        resources: [],
        names: [],
        titles: [],
        additionalResolverGroups: [],
    },
    setEngagement: () => { },
    workflowPollTimeout: 0,
    resetWorkflowPollTimeout: () => { },
    endWorkflowPolling: () => { },
});

export function useEngagementDetailPageContext(): EngagementDetailPageContextProps {
    return useContext<EngagementDetailPageContextProps>(EngagementDetailPageContext);
}