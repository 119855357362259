import { FlashbarProps } from "@amzn/awsui-components-react";
import { createContext, useContext } from "react";
import { DataAccessService } from './core/api';

export interface AppContextProps {
    featureFlags: object;
    client: DataAccessService;
    notifications: any[];
    seeAllEngagementsManagerToggle: boolean;
    seeAllEngagementsResourceToggle: boolean;
    setSeeAllEngagementsManagerToggle: (value: boolean) => void;
    setSeeAllEngagementsResourceToggle: (value: boolean) => void;
    setNotifications: (notifications: FlashbarProps.MessageDefinition[]) => void;
    appendNotification: (notification: FlashbarProps.MessageDefinition) => void;
}

export const AppContext = createContext<AppContextProps>({
    featureFlags: {},
    client: new DataAccessService(),
    notifications: [],
    seeAllEngagementsManagerToggle: false,
    seeAllEngagementsResourceToggle: false,
    setSeeAllEngagementsManagerToggle: () => { },
    setSeeAllEngagementsResourceToggle: () => { },
    setNotifications: () => { },
    appendNotification: () => { }
});

export function useAppContext(): AppContextProps {
    return useContext<AppContextProps>(AppContext);
}