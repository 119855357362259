import { ExpandableSection } from '@amzn/awsui-components-react';
import { ReactNode, useState } from 'react';
import './ExpandableTableRow.css';

export interface ExpandableTableRowProps {
  rowData: any;
  renderExpandedContent: (any) => ReactNode;
  headerContent: ReactNode;
  rowIcon?: ReactNode;
}

export const ExpandableTableRow = (props: ExpandableTableRowProps) => {
  const [expandedContent, setExpandedContent] = useState<ReactNode>(undefined);

  return (
    <div className='expandable-section'>
      <ExpandableSection
        onChange={async (changeProps) => {
          if (changeProps.detail.expanded) {
            setExpandedContent(await props.renderExpandedContent(props.rowData));
          }
        }}
        variant="footer"
        className='expandable-row'
        header={
          <div className='expandable-section-header'>
            {props.rowIcon}
            <div data-testid='row-header-content' className='expandable-section-header-content'>
              {props.headerContent}
            </div>
          </div>
        }>
        <div data-testid='row-expanded-content' className='expandable-section-details'>
          {expandedContent}
        </div>
      </ExpandableSection>
    </div>
  );
}