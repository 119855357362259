import { Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import { useState } from 'react';
import { useInterval } from '../../../core/hooks';
import { useAppContext } from '../../../AppContext';
import { IS_IT_DOWN_LINK } from '../../../core/constants';

// Banner for https://is-it-down.corp.amazon.com/aws-ace-esp
export const IsItDownBanner = () => {
    const [bannerItems, setBannerItems] = useState<FlashbarProps.MessageDefinition[]>([]);
    const { client } = useAppContext();

    // Polls for new banner messages every 60 seconds
    // Checks first to make sure it is not already set to prevent re-renders
    useInterval(() => {
        client.axios.get(IS_IT_DOWN_LINK, {withCredentials: true})
            .then((response) => {
                const bannerInfo = response.data?.dashboards?.dashboard?.components[0];
                if (bannerInfo?.override_indicator > 0) {
                    const bannerItem = {
                        type: getFlashbarType(bannerInfo.override_indicator) || 'info',
                        content: bannerInfo?.override_status,
                        id: 'is-it-down-banner'
                    };
                    // only set if value has changed
                    const hasIdenticalItem = bannerItems.length === 1 && JSON.stringify(bannerItems[0]) === JSON.stringify(bannerItem);
                    const shouldSetBannerItem = (bannerItems.length === 0 || !hasIdenticalItem);
                    if (shouldSetBannerItem) {
                        setBannerItems([bannerItem]);
                    }
                }
                else if (bannerItems.length !== 0) {
                    setBannerItems([]);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, 60000);

    return (<Flashbar items={bannerItems} />)
}

const getFlashbarType = (indicator: number): FlashbarProps.Type => {
    switch (indicator) {
        case 3:
            return 'error';
        case 2:
            return 'warning';
        case 1:
        default:
            return 'info';
    }
}