import { Box, FormField, Input } from "@amzn/awsui-components-react";

/*
    EPDisabledInput is a very generic Input with a label that will always be disabled
    to user input.
*/

export interface EPDisabledInputProps {
    id: string;
    value: string;
    label: string;
    secondaryLabel: string;
}

export const EPDisabledInput = (props: EPDisabledInputProps) => {

    return (
        <Box>
            <FormField
                description={`${props.secondaryLabel}`}
                label={`${props.label}`}
            >
                <Input id={props.id} value={props.value} disabled={true} />
            </FormField>
        </Box>
    );
}