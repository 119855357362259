import {
    Box, ColumnLayout,
    Container,
    Header,
    Link, SpaceBetween, Spinner, Button, Icon
} from '@amzn/awsui-components-react';
import { useAppContext } from '../../../AppContext';
import {
    AnalyticsEvent,
    AnalyticsEventType,
    CTI_LINK,
    DETAIL_PAGE_DETAIL_CONTAINER_DESCRIPTION,
    DETAIL_PAGE_DETAIL_CONTAINER_TITLE, PHONETOOL_LINK
} from '../../../core/constants';
import { User } from '../../../core/user';
import {
    isEngagementDeleteable,
    isEngagementInWarningState,
} from '../../../core/utils/engagement-utils/EngagementUtils';
import { useEngagementDetailPageContext } from '../../../pages/EngagementDetailPageContext';
import { ValueWithLabel } from '../../shared';
import { CTIRGStatusLabel } from '../../shared/cti-rg-status-label';
import { ResourceDeletionModal } from '../../shared/resource-deletion-modal';
import { PartnersContainer } from './PartnersContainer';
import { TicketsContainer } from './TicketsContainer';
import {getLinkWithOpenTicketsCount, ticketHeader} from "../../../helpers/tickets";
import { useState } from 'react';
import {AlertModal} from "../../shared/alert-modal";

export interface DetailsTabProps {
    loading: boolean;
    loadData: () => void;
    confirmDeletion: () => void;
}

export const DetailsTab = (props: DetailsTabProps) => {
    const { engagement } = useEngagementDetailPageContext();
    const { featureFlags } = useAppContext();
    const [alertModalVisible, setAlertModalVisible] = useState<boolean>(false);

    const sbrValue: string | undefined = engagement.sbrFullName ?
        `${engagement.sbrFullName}, ${engagement.securityBarRaiser}` :
        engagement.securityBarRaiser;
    const dpmValue: string | undefined = engagement.dpmFullName ?
        `${engagement.dpmFullName}, ${engagement.deliveryPracticeManager}` :
        engagement.deliveryPracticeManager;
    const oppOwnerValue: string | undefined = engagement.opportunityOwnerFullName ?
        `${engagement.opportunityOwnerFullName}, ${engagement.opportunityOwner}` :
        engagement.opportunityOwner

    let user: User = new User();
    const modals = <Box>
        <AlertModal
            setVisibility={setAlertModalVisible}
            visible={alertModalVisible}
            closeAction={() => setAlertModalVisible(false)}
            closeEngagementEnabled={featureFlags["engagement_closure"]?.enabled}
            engagement={engagement}
            userAlias={user.userId}
            loadData={props.loadData}/>
    </Box>;

    return (
        <SpaceBetween size='l'>
            <Container header={<Header
                variant='h2'
                description={DETAIL_PAGE_DETAIL_CONTAINER_DESCRIPTION}
                actions={featureFlags['engagement_closure']?.enabled && isEngagementDeleteable(user.userId, engagement) &&
                    <ResourceDeletionModal
                        confirm={props.confirmDeletion}
                        engagement={engagement}
                        isRenderedAsIcon={false}
                        ctiName={`AWS Professional Services → ${engagement.segment} → ${engagement.primaryResolverGroup}`}
                    />
                }>
                {DETAIL_PAGE_DETAIL_CONTAINER_TITLE}
            </Header>}>
                {!engagement.name && <Box textAlign='center'>
                    <Spinner size='large' />
                </Box>}
                {engagement.name && <ColumnLayout columns={3} variant='text-grid'>
                    <SpaceBetween size='l'>
                        <ValueWithLabel label='Security Bar Raiser' value={sbrValue}>
                            <Link
                                href={PHONETOOL_LINK(engagement.securityBarRaiser)}
                                external={true}
                                data-csm-on='click'
                                data-csm-name={AnalyticsEventType.LinkClick}
                                data-csm-attrs={`engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${AnalyticsEvent.PhoneToolLink}`}
                            >
                                {sbrValue}
                            </Link>
                        </ValueWithLabel>
                        <ValueWithLabel label='Delivery Practice Manager' value={dpmValue}>
                            <Link
                                href={PHONETOOL_LINK(engagement.deliveryPracticeManager)}
                                external={true}
                                data-csm-on='click'
                                data-csm-name={AnalyticsEventType.LinkClick}
                                data-csm-attrs={`engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${AnalyticsEvent.PhoneToolLink}`}
                            >
                                {dpmValue}
                            </Link>
                        </ValueWithLabel>
                        <ValueWithLabel label='Opportunity Owner' value={oppOwnerValue}>
                            {engagement.opportunityOwner !== 'sfdc-proserve' &&
                                <Link
                                    href={PHONETOOL_LINK(engagement.opportunityOwner)}
                                    external={true}
                                    data-csm-on='click'
                                    data-csm-name={AnalyticsEventType.LinkClick}
                                    data-csm-attrs={`engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${AnalyticsEvent.PhoneToolLink}`}
                                >
                                    {oppOwnerValue}
                                </Link>}
                            {engagement.opportunityOwner === 'sfdc-proserve' &&
                                engagement.opportunityOwner
                            }
                        </ValueWithLabel>

                    </SpaceBetween>
                    <SpaceBetween size='l'>
                        <ValueWithLabel label='Customer' value={engagement.customerName}>
                            {`${engagement.customerName} (${engagement.customerId})`}
                        </ValueWithLabel>
                        <ValueWithLabel label='City' value={engagement.city} />
                        <ValueWithLabel label='Region' value={engagement.region} />
                    </SpaceBetween>
                    <SpaceBetween size='l'>
                        {/* Link would not appear when it was set to value so moved outside*/}
                        {engagement.primaryResolverGroup && <ValueWithLabel label={ticketHeader} value={" "}>
                            {engagement.activeCTI !== undefined ?
                            getLinkWithOpenTicketsCount(engagement.openTickets, engagement.primaryResolverGroup, engagement.projectId, engagement.segment) : 'N/A'
                            }
                        </ValueWithLabel>
                        }

                        {engagement.primaryResolverGroup && engagement.activeCTI
                            ?
                            <SpaceBetween size='s'>
                                <ValueWithLabel label='CTI/Resolver Group' value={`AWS Professional Services → ${engagement.segment} → ${engagement.primaryResolverGroup}`} />
                                <ValueWithLabel label='' value={engagement.id}>
                                    <Link
                                        href={`${CTI_LINK}/group/${engagement.primaryResolverGroup}`}
                                        external={true}
                                        data-csm-on='click'
                                        data-csm-name={AnalyticsEventType.LinkClick}
                                        data-csm-attrs={`engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${AnalyticsEvent.RGLink}`}
                                    >
                                        Resolver Group
                                    </Link>
                                </ValueWithLabel>
                            </SpaceBetween>
                            :
                            <SpaceBetween size='s'>
                                <ValueWithLabel label='CTI/Resolver Group' value={engagement.id}>
                                    <CTIRGStatusLabel engagement={engagement} />
                                </ValueWithLabel>
                            </SpaceBetween>
                        }
                        <ValueWithLabel label='Parent Engagement' value={engagement.parentEngagement}>
                            <Link
                                href={`/engagements/${engagement.parentEngagement}`}
                                external={true}
                                data-csm-on='click'
                                data-csm-name={AnalyticsEventType.ButtonClick}
                                data-csm-attrs={`engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${AnalyticsEvent.ParentEngagementButton}`}
                            >
                                {engagement.parentEngagement}
                            </Link>
                        </ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>}
                {modals}
            </Container>
            <ColumnLayout columns={2}>
                <TicketsContainer /> 
                <PartnersContainer />
            </ColumnLayout>
        </SpaceBetween>
    );
}
