import {GetTicketsResponse} from "../../../../../backend-layer/backend/src/lib/dataLayer/dto/Ticket";
import {DataAccessService} from "../core/api";
import {Engagement} from "../core/types";
import {Link} from "@amzn/awsui-components-react";
import * as TableConstants from "../core/constants";

// Used in place for open RG ticket count if error encountered in displaying
export const DEFAULT_TICKETS_ERROR_MESSAGE = "Failed to display tickets";

// Used in the dashboard and table page to display ticket count header
export const ticketHeader = 'Open RG Tickets';

export async function getOpenTicketsCountFromEngagementId(client: DataAccessService, engagementId:string) :Promise<number | Error> {
    try{
        const ticketsResponse = await client.get(`/ts/engagements/${engagementId}/tickets`) as GetTicketsResponse
        return ticketsResponse.total_results;
    }
    catch (err) {
        // If request fails, it is logged in backend lambda
        return Error(`Error getting open tickets count from engagement id: ${engagementId}`);
    }
}

// Retrieves the RG link and open tickets asynchronously
export async function getRgLink(client: DataAccessService, id: string): Promise<JSX.Element | null> {
    const engagement: Engagement = await client.get(`/ts/engagements/${id}`) as Engagement;
    if (engagement.primaryResolverGroup) {

        const openTicketsResponse = await getOpenTicketsCountFromEngagementId(client, engagement.id);

        if (Number.isInteger(openTicketsResponse)) {
            engagement.openTickets = openTicketsResponse as number;
        }
        return getLinkWithOpenTicketsCount(engagement.openTickets, engagement.primaryResolverGroup, engagement.projectId, engagement.segment)
    }
    return null
}

// Used to generate link when open tickets counts is already known
export function getLinkWithOpenTicketsCount(openTicketsCount: number|undefined, primaryResolverGroup:string, projectId: string, segment:string|undefined) :  JSX.Element {
    return (
        <Link
            href={TableConstants.RG_Tickets_LINK(primaryResolverGroup)}
            external={true}
            data-csm-on='click'
            data-csm-name={TableConstants.AnalyticsEventType.LinkClick}
            data-csm-attrs={`engagement_id:${projectId},segment:${segment},element_type:${TableConstants.AnalyticsEvent.RGTicketsLink}`}
        >
            {openTicketsCount ?? DEFAULT_TICKETS_ERROR_MESSAGE}
        </Link>
    )
}