import { Box, Button, Grid, Link, SpaceBetween } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { RGMember, RGRole } from "../../../core/types/RGMember";
import { ModifyResourcesProps } from "../../../core/types/ModifyResourcesProps";
import { SingleUseSelect } from "../../shared/single-use-select/index";
import { SupportOrderInput } from "../../shared/support-order-input/index";
import {Salesforce_LINK} from "../../../core/constants";
import './ResolverGroupMemberEditor.css';

export interface RGMemberEditorProps extends ModifyResourcesProps {
    engagementSfdcId: string;
    resourceCap: number;
    clearAfterRequest: boolean;
    loading: boolean;
    itemsChanged: (newItems: RGMember[], size: number) => void;
    resetAfterClearRequest: () => void;
}

export const ResolverGroupMemberEditor = (props: RGMemberEditorProps) => {

    function addItem(event) {
        // Put empty item onto list
        items.push({ alias: "", fullName: "", title: "", role: RGRole.MEMBER, support_order: undefined });

        // Disable add button if at cap for number of items to display at once
        if (items.length >= props.resourceCap) {
            setAddButtonDisabled(true);
        }

        // Notify parent of item added
        props.itemsChanged(items, items.length);
    }

    function removeItem(event, index) {
        // Remove item from used resources
        props.removeFromUsedResources(items[index].selection_value);

        // Remove item
        items.splice(index, 1);

        // Child and Parent synchronization
        props.itemsChanged(items, items.length);

        setAddButtonDisabled(false);
    }

    function getAttrsOnSelectionChangeResource(label: string, value: string, index: number) {
        let oldValue: string | undefined = items[index].alias;

        // Update used resources
        props.removeFromUsedResources(oldValue);
        props.addToUsedResources(value);

        // Change item values in place
        items[index].selection_label = label;
        items[index].selection_value = value;
        items[index].alias = value;
        props.itemsChanged(items, items.length);
    }

    function getAttrsOnChangeSupport(value: number | undefined, index: number) {
        const temp = [...items];
        temp[index].support_order = value;
        setItems(temp);
        props.itemsChanged(items, items.length);
    }

    // State variables
    const [items, setItems] = useState<RGMember[]>([{ alias: "", fullName: "", title: "", role: RGRole.MEMBER, support_order: undefined }]);
    const [addButtonDisabled, setAddButtonDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (props.clearAfterRequest) {
            setItems([{ alias: "", fullName: "", title: "", role: RGRole.MEMBER, support_order: undefined }]);
            setAddButtonDisabled(false);
            props.resetAfterClearRequest();
        }
    });

    return (
        <Box>
            <SpaceBetween size="m">
                {items.map((value, index, array) => {
                    let supportOrderInputId: string = `soInput${index}`;
                    let singleUseSelectId: string = `suSelect${index}`;
                    return (
                        <Box id={`${index}`}>
                            <SpaceBetween size="s">
                                <Grid gridDefinition={[{ colspan: 5 }, { colspan: 4 }, { colspan: 3 }]}>
                                    <SingleUseSelect
                                        id={singleUseSelectId}
                                        label="Resource"
                                        secondaryLabel="Team member to add to the Resolver Group."
                                        empty="All resources are in use"
                                        placeholder="Select a resource"
                                        index={index}
                                        selectOptions={props.getRemainingResources()}
                                        selectedOption={value.selection_label ? { label: value.selection_label, value: value.selection_value } : null}
                                        onSelectionChangeNotifyAttrs={getAttrsOnSelectionChangeResource}
                                        disabled={props.loading} />
                                    <SupportOrderInput
                                        id={supportOrderInputId}
                                        index={index}
                                        value={value.support_order !== undefined ? value.support_order.toString() : ""}
                                        onChangeNotifyAttrs={getAttrsOnChangeSupport}
                                        disabled={props.loading}
                                    />
                                    <div className='modal-remove-col'>
                                        <Button id={`removeButton${index}`} disabled={props.loading} onClick={(event) => removeItem(event, index)} variant="normal">
                                            Remove
                                        </Button>
                                    </div>
                                </Grid>
                            </SpaceBetween>
                        </Box>
                    )
                })}
                <Box>
                    <Button id='addResourceButton' iconName="add-plus" iconAlign="left" disabled={addButtonDisabled || props.loading} variant="normal" onClick={(event) => addItem(event)}>
                        Add Another Resource
                    </Button>
                </Box>
                <Box>
                    <hr style={{ borderTop: '1px rounded gray' }} />
                    <p style={{ fontSize: 14 }}>Missing a resource? <Link href={Salesforce_LINK(props.engagementSfdcId)} external>Add resources in Salesforce</Link><br />
                        <span style={{ color: 'gray', fontSize: 14 }}>Changes made in Salesforce can take up to 4 hours to display in the Engagement Security Portal.</span></p>
                </Box>
            </SpaceBetween>
        </Box>
    );
}
