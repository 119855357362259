import { TableFilter } from "../../shared/filter-toggle";
import {
    isEngagementActive,
    filterForActiveEngagements
} from "../../../core/utils/engagement-utils/EngagementUtils";


export enum FilterPredicate {
    Active,
    All
}

export class EngagementTableFilter extends TableFilter {
    // TODO: Remove isEhiActiveFFEnabled argument once EHI Active feature has been approved.
    // [Flag - ff-frontend-ehi-active-20230221]
    constructor(filter: FilterPredicate, isEhiActiveFFEnabled?: boolean) {
        super();
        switch (filter) {
            case FilterPredicate.Active:
                this.filterPredicate = isEhiActiveFFEnabled ? filterForActiveEngagements : isEngagementActive;
                break;
            case FilterPredicate.All:
                break;
        }
    }
}
