export default class SessionStorage {

    static get(key) {
        let val
        let item = sessionStorage.getItem(key);
        val = item !== null ? JSON.parse(item) : item
        return val
    }

    static set(key, value) {
        let val
        try {
            val = JSON.stringify(value)
        } catch {
            val = value
        }
        sessionStorage.setItem(key, val);
    }

    static remove(key) {
        sessionStorage.removeItem(key)
    }
}
