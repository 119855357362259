import { useState } from 'react';
import { useAppContext } from "../AppContext";

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TopNavigation } from '@amzn/awsui-components-react';
import { SearchBar } from "./search-bar/SearchBar";

import { User } from '../core/user';

import iProServe from '../../media/images/proserve.png'
import { PHONETOOL_LINK } from '../core/constants';
import '../../styles/components/service-header.scss';


const ServiceHeader = (props: RouteComponentProps) => {
    const [user, setUser] = useState<User>(new User());
    const { featureFlags } = useAppContext();

    return (<TopNavigation
        className='header'
        identity={{
            href: '/',
            logo: {
                src: iProServe,
                alt: 'Service'
            }
        }}
        utilities={[
            {
                type: 'menu-dropdown',
                text: user.full_name,
                description: user.email,
                iconName: 'user-profile',
                items: [
                    { id: 'profile', text: 'Profile', href: PHONETOOL_LINK(user.userId) }
                ]
            }
        ]}
        i18nStrings={{
            overflowMenuTitleText: 'More',
            searchIconAriaLabel: 'Search',
            searchDismissIconAriaLabel: 'Close search',
            overflowMenuTriggerText: 'More'
        }}
        search={featureFlags["ff-frontend-search-bar-20230117"]?.enabled && <SearchBar />}
    />
    )
};

export default withRouter(ServiceHeader);
