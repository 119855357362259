export const ENGAGEMENT_LOAD_ERROR_FLASHBAR = 'Engagement data not found.';
export const RG_LOAD_ERROR_FLASHBAR = 'No resolver group found for this engagement.';
export const DEFAULT_ERROR_FLASHBAR = 'Failed to load engagement/resolver group data. Please reach out if this problem persists.';
export const DASHBOARD_PAGE_NAME = 'Dashboard';
export const DASHBOARD_PAGE_TITLE = 'Engagements';
export const DASHBOARD_PAGE_LINK = '/engagements';
export const ALTERNATE_ENGAGEMENT_FLASHBAR = 'All of the Project IDs have been affected by the Lightning Migration. Click here if you are looking for the following engagement: ';
export const MANAGED_ENGAGEMENTS_TABLE_TITLE = 'My Engagements';
export const MANAGED_ENGAGEMENTS_TABLE_DESCRIPTION = 'A list of engagements you manage.';
export const RESOURCE_ENGAGEMENTS_TABLE_TITLE = 'Other Engagements';
export const RESOURCE_ENGAGEMENTS_TABLE_DESCRIPTION = 'A list of engagements you are assigned as a resource.';
export const ENGAGEMENTS_TABLE_LOADING_TEXT = 'Loading engagements';
// TODO: Once EHI Active feature has been approved, replace ENGAGEMENTS_TABLE_TOGGLE_*_TEXT w/ENGAGEMENTS_TABLE_TOGGLE_*_TEXT_FF
// [Flag - ff-frontend-ehi-active-20230221]
export const ENGAGEMENTS_TABLE_TOGGLE_ON_TEXT = 'Include closed engagements on';
export const ENGAGEMENTS_TABLE_TOGGLE_OFF_TEXT = 'Include closed engagements off';
export const ENGAGEMENTS_TABLE_TOGGLE_ON_TEXT_FF = "Include inactive engagements: On";
export const ENGAGEMENTS_TABLE_TOGGLE_OFF_TEXT_FF = "Include inactive engagements: Off";
export const ENGAGEMENTS_TABLE_EXPANDED_CONTENT_TITLE = 'View Tickets';
export const RG_MEMBER_UPDATE_FLASHBAR_TEXT = (numRemoved: number) => `Your request to add ${numRemoved} member(s) to the Resolver Group has been submitted. Click on the refresh button to review changes. Note: Changes can take up to 1 minute to display.`
export const RG_MEMBER_EDIT_FLASHBAR_TEXT = (numRemoved: number) => `Your request to edit ${numRemoved} member(s) support order from the Resolver Group has been submitted. Click on the refresh button to review changes. Note: Changes can take up to 1 minute to display.`
export const RG_MEMBER_REMOVE_FLASHBAR_TEXT = (numRemoved: number) => `Your request to remove ${numRemoved} member(s) from the Resolver Group has been submitted. Click on the refresh button to review changes. Note: Changes can take up to 1 minute to display.`
export const UNDEFINED_VALUE = 'N/A';
export const DETAIL_PAGE_TICKET_CONTAINER_DESCRIPTION = 'Helpful links to view assigned tickets for this engagement';
export const DETAIL_PAGE_TICKET_CONTAINER_TITLE = 'View Tickets';
export const DETAIL_PAGE_PARTNER_CONTAINER_DESCRIPTION = 'List of partners that are a part of this engagement';
export const DETAIL_PAGE_PARTNER_CONTAINER_TITLE = 'Partners';
export const DETAIL_PAGE_DETAIL_CONTAINER_DESCRIPTION = 'Additional information on this engagement';
export const DETAIL_PAGE_DETAIL_CONTAINER_TITLE = 'Engagement Details';
export const DASHBOARD_PAGE_TICKETS_DESCRIPTION = 'Quick access to view other tickets';

export const RG_MEMBER_OPERATIONS_IN_PROGRESS = (numAdded: number, numRemoved: number, numModified: number) => {
    let content = `Your requests to the Resolver Group have been submitted. `
    const operations : string[] = []
    if (numAdded) {
        const plural = numAdded > 1 ? "s" : ""
        operations.push(`${numAdded} member addition${plural}`)
    }
    if (numRemoved) {
        const plural = numRemoved > 1 ? "s" : ""
        operations.push(`${numRemoved} member deletion${plural}`)
    }
    if (numModified) {
        const plural = numModified > 1 ? "s" : ""
        operations.push(`${numModified} member edit${plural}`)
    }
    return content + operations.join(", ") + " in progress";
}

export const RG_MEMBER_OPERATIONS_SUCCEEDED = (numAdded?: number, numRemoved?: number, numModified?: number) => {
    let content = `Operations succeeded: `
    const operations : string[] = []
    if (numAdded) {
        const plural = numAdded > 1 ? "s" : ""
        operations.push(`${numAdded} member${plural} added`)
    }
    if (numRemoved) {
        const plural = numRemoved > 1 ? "s" : ""
        operations.push(`${numRemoved} member${plural} deleted`)
    }
    if (numModified) {
        const plural = numModified > 1 ? "s" : ""
        operations.push(`${numModified} member${plural} edited`)
    }
    return content + operations.join(", ") + ". Please click refresh or close this banner to see the updates."
}

export const RG_MEMBER_OPERATIONS_FAILED = (numAdded?: number, numRemoved?: number, numModified?: number) => {
    let content = `Operations failed: `
    const operations : string[] = []
    if (numAdded) {
        const plural = numAdded > 1 ? "s" : ""
        operations.push(`${numAdded} member${plural} failed to add`)
    }
    if (numRemoved) {
        const plural = numRemoved > 1 ? "s" : ""
        operations.push(`${numRemoved} member${plural} failed to delete`)
    }
    if (numModified) {
        const plural = numModified > 1 ? "s" : ""
        operations.push(`${numModified} member${plural} failed to edit`)
    }
    return content + operations.join(", ")
}

export const RG_MEMBER_OPERATIONS_TIMED_OUT = "Your request has been submitted, but it might take a day before completing. Retry again tomorrow if the action doesn't display."