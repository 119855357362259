import { Engagement } from "../../../core/types";
import { Box, Button, Grid, Link, Spinner } from "@amzn/awsui-components-react";
import { EngagementStatusLabel, InternalLink, ValueWithLabel } from "../../shared";
import * as TableConstants from "../../../core/constants";
import { isEngagementDeleteable, isEngagementInWarningState } from "../../../core/utils/engagement-utils/EngagementUtils";
import { ResourceDeletionModal } from "../../shared/resource-deletion-modal";
import { CTIRGStatusLabel } from "../../shared/cti-rg-status-label";
import {
    getLinkWithOpenTicketsCount,
    getOpenTicketsCountFromEngagementId,
    ticketHeader
} from "../../../helpers/tickets";
import { User } from "../../../core/user";
import { DataAccessService } from "../../../core/api";
import { useEffect, useState } from "react";
import { AlertModal } from "../../shared/alert-modal";
import { alertIconSvg } from "../../../../media/svg/alertIcon";
import { useAppContext } from "../../../AppContext";

export interface EngagementsRowProps {
    engagement: Engagement;
    user: User;
    confirmDeletion: () => void;
    client: DataAccessService;
    loadData: (refresh?: boolean) => void;
}

export const EngagementsRow = (props: EngagementsRowProps) => {
    const { featureFlags } = useAppContext();
    const [alertModalVisible, setAlertModalVisible] = useState<boolean>(false);
    const undefinedTicketsState = -1;

    // Need to fetch these engagement fields, as they are not passed in from engagement of dashboard page
    const [engagementOpenTickets, setEngagementOpenTickets] = useState<number>(undefinedTicketsState);


    useEffect(() => {
        // If cancel is set to true, then the component has been unmounted and will cancel state update.
        // Prevents memory leak warning explained here: https://dev.to/jexperton/how-to-fix-the-react-memory-leak-warning-d4i
        let cancel = false;

        if (props.engagement.activeCTI !== undefined) {
            // Make the api call to fetch the tickets here
            getOpenTicketsCountFromEngagementId(props.client, props.engagement.id).then(
                response => {
                    if (cancel) {
                        return
                    }
                    if (typeof response === 'number') {
                        setEngagementOpenTickets(response as number);
                    }
                }
            ).catch(
                error => {
                    console.error(`Request to get tickets failed for engagement id: ${props.engagement.id}`);
                }
            );
        }

        return () => {
            cancel = true;
        }

    }, [props.engagement])

    const modals = <Box>
        <AlertModal
            setVisibility={setAlertModalVisible}
            visible={alertModalVisible}
            closeAction={() => setAlertModalVisible(false)}
            closeEngagementEnabled={featureFlags["engagement_closure"]?.enabled}
            // passing in the fields that are loaded asynchronously
            engagement={{ ...props.engagement, openTickets: engagementOpenTickets, primaryResolverGroup: props.engagement.primaryResolverGroup, segment: props.engagement.segment }}
            loadData={props.loadData}
            userAlias={props.user.userId} />
    </Box>;

    return (
        <Grid
            gridDefinition={[{ colspan: 2 }, { colspan: 2 }, { colspan: 2 }, { colspan: 2 }, { colspan: 2 }, { colspan: 1 }, { colspan: 1 }]}>
            <Box padding='n' margin='n' variant='p'>
                {modals}
                {/* flex style is used to align the alert icon to the same row as the name (else it appears below)*/}
                <div style={{ display: "flex" }}>
                    <ValueWithLabel
                        label='Engagement'
                        value={
                            <div>
                                <InternalLink
                                    id={`eng-table-id-${props.engagement.projectId}`}
                                    href={`engagements/${props.engagement.projectId}`}
                                    data-csm-on='click'
                                    data-csm-name={TableConstants.AnalyticsEventType.ButtonClick}
                                    data-csm-attrs={`engagement_id:${props.engagement.projectId},segment:${props.engagement.segment},element_type:${TableConstants.AnalyticsEvent.DetailPageButton}`}
                                >{props.engagement.name}</InternalLink>
                            </div>
                        }
                    />
                    {/* Adding padding to the top to align the alert icon closer down to field name (else it appears in middle of row) */}
                    <div style={{ paddingTop: "16px" }}>
                        {isEngagementInWarningState({ ...props.engagement, openTickets: engagementOpenTickets }, featureFlags) &&
                            <Button data-testid={`alert-button-${props.engagement.id}`} variant="icon" iconSvg={alertIconSvg} onClick={event => setAlertModalVisible(true)} />
                        }
                    </div>

                </div>
            </Box>
            <Box padding='n' margin='n' variant='p'>
                <ValueWithLabel
                    label='SFDC Project Link'
                    value={<Link
                        href={TableConstants.Salesforce_LINK(props.engagement.sfdcId)}
                        external={true}
                        data-csm-on='click'
                        data-csm-name={TableConstants.AnalyticsEventType.LinkClick}
                        data-csm-attrs={`engagement_id:${props.engagement.projectId},segment:${props.engagement.segment},element_type:${TableConstants.AnalyticsEvent.SFDCProjectLink}`}
                    >
                        {props.engagement.projectId}
                    </Link>}
                />
            </Box>
            <Box padding='n' margin='n' variant='p'>
                <ValueWithLabel label='Status' value={props.engagement.stage}>
                    <EngagementStatusLabel engagement={props.engagement} />
                </ValueWithLabel>
            </Box>
            <Box padding='n' margin='n' variant='p'>
                <ValueWithLabel label='CTI/Resolver Group Status'
                    value={props.engagement.activeCTI ? 'Active' : 'Deleted'}>
                    {featureFlags['engagement_closure']?.enabled && isEngagementDeleteable(props.user.userId, props.engagement)
                        ?
                        <ResourceDeletionModal
                            confirm={props.confirmDeletion}
                            engagement={props.engagement}
                            isRenderedAsIcon={true}
                            ctiName={`AWS Professional Services → ${props.engagement.segment} → ${props.engagement.primaryResolverGroup}`} />
                        :
                        <CTIRGStatusLabel engagement={props.engagement} />
                    }
                </ValueWithLabel>
            </Box>
            <Box padding='n' margin='n' variant='p'>
                {/* Link would not appear when it was set to value so moved outside*/}
                <ValueWithLabel
                    label={ticketHeader}
                    value={' '}>
                    {engagementOpenTickets === undefinedTicketsState ? props.engagement.activeCTI !== undefined ? <Spinner /> : 'N/A' :
                        getLinkWithOpenTicketsCount(engagementOpenTickets, props.engagement.primaryResolverGroup || '', props.engagement.projectId, props.engagement.segment)}
                </ValueWithLabel>
            </Box>
            <Box padding='n' margin='n' variant='p'>
                <ValueWithLabel label='Start Date' value={props.engagement.startDate} />
            </Box>
            <Box padding='n' margin='n' variant='p'>
                <ValueWithLabel label='End Date' value={props.engagement.endDate} />
            </Box>
        </Grid>
    )

}
