import {
    Alert,
    Box,
    Button,
    Container,
    Header,
    SpaceBetween,
    Spinner
} from "@amzn/awsui-components-react";
import * as _ from "lodash";
import { useEffect, useRef, useState, Fragment } from "react";
import { useAppContext } from "../../../AppContext";
import { AnalyticsEvent, AnalyticsEventType, MODE } from "../../../core/constants";
import { User } from "../../../core/user";
import { convertTo12Hour, convertTo24Hour } from "../../../helpers/utils";
import { useEngagementDetailPageContext } from "../../../pages/EngagementDetailPageContext";
import {
    EditableValueWithLabel,
    TimeSelector,
    TimezoneSelector,
    WeekdaySelector
} from "../../shared";

export interface ConfigurationContainerProps {
    loading: boolean;
    activeCTI: boolean;
}

export const ConfigurationContainer = (props: ConfigurationContainerProps) => {
    const formatWeekdayMask = (weekdayMask: string | undefined) =>
        weekdayMask?.split('').map((item) => parseInt(item))
    const { client, setNotifications } = useAppContext();
    const { engagement, setEngagement } = useEngagementDetailPageContext();
    const [loading, setLoading] = useState(props.loading);
    const [mode, setMode] = useState(MODE.VIEW);
    const [data, setData] = useState({
        timezone: engagement.config?.timezone || '',
        startTime: engagement.config?.startTime || '',
        endTime: engagement.config?.endTime || '',
        weekdayMask: formatWeekdayMask(engagement.config?.weekdayMask)
            || [0, 0, 0, 0, 0, 0, 0],
    });
    const [isValidStartTime, setIsValidStartTime] = useState(true);
    const [isValidEndTime, setIsValidEndTime] = useState(true);
    const [isValidWeekdaySelection, setIsValidWeekdaySelection] = useState(true);
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const timezoneRef = useRef<typeof TimezoneSelector>();
    const startTimeRef = useRef<typeof TimeSelector>();
    const endTimeRef = useRef<typeof TimeSelector>();
    const weekdayRef = useRef<typeof WeekdaySelector>();

    const getWorkWeekView = (weekdayMask: number[]) => {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return daysOfWeek.filter((day, index) => weekdayMask[index] === 1).join(' | ');
    }

    const validateBusinessTimes = (startTime, endTime) => {
        const startHour = parseInt(startTime.split(':')[0]);
        let endHour = parseInt(endTime.split(':')[0]);
        if (startHour >= 21 && endHour <= 3) {
            endHour += 24;
        }
        const timeDiff = endHour - startHour;
        return timeDiff < 0 || timeDiff >= 3;
    }

    const submitConfigurationChanges = async () => {
        const newData = {
            startTime: convertTo24Hour(startTimeRef.current as any),
            endTime: convertTo24Hour(endTimeRef.current as any),
            timezone: timezoneRef.current as any,
            weekdayMask: weekdayRef.current as any,
        };

        const validBusinessHours = validateBusinessTimes(newData.startTime, newData.endTime);
        if (validBusinessHours) {
            if (!_.isEqual(data, newData)) {
                setLoading(true);
                const requestBody = {
                    engagementId: engagement.id,
                    resolverGroup: engagement.primaryResolverGroup,
                    startTime: newData.startTime,
                    endTime: newData.endTime,
                    weekdayMask: newData.weekdayMask.join(""),
                    timezone: newData.timezone,
                };
                try {
                    await client.post(
                        `/ts/engagements/${engagement.id}/config`,
                        requestBody
                    );
                    setEngagement({
                        ...engagement,
                        config: {
                            timezone: requestBody.timezone,
                            startTime: requestBody.startTime,
                            endTime: requestBody.endTime,
                            weekdayMask: requestBody.weekdayMask,
                        }
                    });
                } catch (error) {
                    setNotifications([{
                        content: 'Configuration update failed',
                        type: 'error',
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                    }]);
                }
                setLoading(false);
            }
            setAlertVisible(false);
            setMode(MODE.VIEW);
        } else {
            setAlertVisible(true);
            setAlertMessage('Business hours must be at least 3 hours long. Please use different start and end times.');
        }



    };

    const hasValidInputs = () => isValidStartTime && isValidEndTime && isValidWeekdaySelection;
    const isEditable = () =>
        engagement.config?.endTime &&
            engagement.config?.startTime &&
            engagement.config?.weekdayMask
            ? true
            : false;

    function getActions(mode: MODE) {
        const user: User = new User();
        if ((user.userId as string) === engagement.manager && !loading && props.activeCTI) {
            if (mode === MODE.VIEW) {
                return (
                    <Button disabled={!isEditable()} onClick={() => setMode(MODE.EDIT)}>
                        Edit
                    </Button>
                );
            } else if (mode === MODE.EDIT) {
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "250px",
                        }}
                    >
                        <Button
                            disabled={!hasValidInputs()}
                            loading={loading}
                            variant="primary"
                            onClick={submitConfigurationChanges}
                            data-csm-on='click'
                            data-csm-name={AnalyticsEventType.ButtonClick}
                            data-csm-attrs={`engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${AnalyticsEvent.UpdateTimezoneButton}`}
                        >
                            Apply Changes
                        </Button>
                        <Button
                            disabled={loading}
                            onClick={() => {
                                setMode(MODE.VIEW);
                                setAlertVisible(false);
                            }}>
                            Cancel
                        </Button>
                    </div>
                );
            }
        }
    }

    useEffect(() => {
        setLoading(props.loading);
        setData({
            timezone: engagement.config?.timezone || 'America/Los_Angeles',
            startTime: engagement.config?.startTime || '09:00',
            endTime: engagement.config?.endTime || '17:00',
            weekdayMask: formatWeekdayMask(engagement.config?.weekdayMask)
                || [0, 0, 0, 0, 0, 0, 0],
        });
    }, [props.loading, engagement]);

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    description="Set the best time for your team in the resolver group to receive tickets."
                    actions={getActions(mode)}
                >
                    Resolver Group Settings
                </Header>
            }
        >
            {loading && (
                <Box textAlign="center">
                    <Spinner size="large" />
                </Box>
            )}
            {!loading && (
                <SpaceBetween size="l">
                    <Alert
                        onDismiss={() => setAlertVisible(false)}
                        visible={alertVisible && mode === MODE.EDIT}
                        dismissAriaLabel="Close alert"
                        dismissible
                        type="error"
                    >
                        {alertMessage}
                    </Alert>
                    <EditableValueWithLabel
                        label="Timezone"
                        mode={mode}
                        value={engagement.config?.timezone}
                        viewMode={<Fragment>{data.timezone}</Fragment>}
                        editMode={
                            <TimezoneSelector ref={timezoneRef} zone={data.timezone} />
                        }
                    />
                    <EditableValueWithLabel
                        label="Business Hours Start Time"
                        mode={mode}
                        value={engagement.config?.startTime}
                        viewMode={<Fragment>{convertTo12Hour(data.startTime)}</Fragment>}
                        editMode={
                            <TimeSelector
                                ref={startTimeRef}
                                time={convertTo12Hour(data.startTime)}
                                isValid={isValidStartTime}
                                setIsValid={setIsValidStartTime}
                                mode={mode}
                            />
                        }
                    />
                    <EditableValueWithLabel
                        label="Business Hours End Time"
                        mode={mode}
                        value={engagement.config?.endTime}
                        viewMode={<Fragment>{convertTo12Hour(data.endTime)}</Fragment>}
                        editMode={
                            <TimeSelector
                                ref={endTimeRef}
                                time={convertTo12Hour(data.endTime)}
                                isValid={isValidEndTime}
                                setIsValid={setIsValidEndTime}
                                mode={mode}
                            />
                        }
                    />
                    <EditableValueWithLabel
                        label="Working Days"
                        mode={mode}
                        value={getWorkWeekView(data.weekdayMask)}
                        viewMode={getWorkWeekView(data.weekdayMask)}
                        editMode={<WeekdaySelector
                            ref={weekdayRef}
                            data={data.weekdayMask}
                            isValid={isValidWeekdaySelection}
                            setIsValid={setIsValidWeekdaySelection}
                        />}
                    />
                </SpaceBetween>
            )}
        </Container>
    );
};
