import {
    Box, ColumnLayout,
    Container,
    Header,
    Link
} from '@amzn/awsui-components-react';
import { AnalyticsEvent, AnalyticsEventType, DETAIL_PAGE_PARTNER_CONTAINER_DESCRIPTION, DETAIL_PAGE_PARTNER_CONTAINER_TITLE, SALESFORCE_PARTNER_LINK } from '../../../core/constants';
import { useEngagementDetailPageContext } from '../../../pages/EngagementDetailPageContext';
import { isGcr } from '../../../helpers/utils';

export const PartnersContainer = () => {
    const { engagement } = useEngagementDetailPageContext();

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    description={DETAIL_PAGE_PARTNER_CONTAINER_DESCRIPTION}
                >
                    {DETAIL_PAGE_PARTNER_CONTAINER_TITLE}
                </Header>
            }
        >
            {!isGcr &&
                <ColumnLayout borders='horizontal' columns={1}>
                    {engagement.partners?.map((partner) => {
                        return <Box padding='n' margin='n' variant='p'>
                            <Link
                                href={SALESFORCE_PARTNER_LINK(partner.id)}
                                external={true}
                                data-testid={`${partner.name}-link`}
                                data-csm-on='click'
                                data-csm-name={AnalyticsEventType.LinkClick}
                                data-csm-attrs={`engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${AnalyticsEvent.SFDCPartnersLink}`}
                            >
                                {`${partner.name} ${partner.accountName}`}
                            </Link>
                        </Box>
                    })}
                </ColumnLayout>
            }
            {isGcr &&
                <>ESP does not currently support partner data in GCR</>
            }
        </Container>
    );
}
