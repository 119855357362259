import {Box, ColumnLayout, Grid, Link} from '@amzn/awsui-components-react';
import {Engagement} from '../../core/types';
import * as TableConstants from '../../core/constants';
import {ValueWithLabel} from "../shared";
import {
    AnalyticsEvent,
    AnalyticsEventType,
    DASHBOARD_PAGE_TICKETS_DESCRIPTION,
    PHONETOOL_LINK
} from "../../core/constants";
import React from 'react';
import { isGcr } from '../../helpers/utils';

export type ExpandedEngagementDetailProps = {
    engagement: Engagement;
}

export const ExpandedEngagementDetail = (props: ExpandedEngagementDetailProps) => {

    const emAlias = props.engagement.manager;
    const emName = props.engagement.managerFullName;
    return (
        <ColumnLayout borders="horizontal">
            <Grid gridDefinition={[{colspan: 2}, {colspan: 2}]}>
                <Box padding='n' margin='n' variant='p'>
                    <ValueWithLabel label='Project Manager' value={emAlias}>
                        <Link
                            href={PHONETOOL_LINK(emAlias)}
                            external={true}
                            data-csm-on='click'
                            data-csm-name={AnalyticsEventType.LinkClick}
                            data-csm-attrs={`engagement_id:${props.engagement.projectId},segment:${props.engagement.segment},element_type:${AnalyticsEvent.PhoneToolLink}`}
                        >
                            {emName ? `${emName}, ${emAlias}`: `${emAlias}`}
                        </Link>
                    </ValueWithLabel>
                </Box>

                <Box padding='n' margin='n' variant='p'>
                    <ValueWithLabel label='Customer' value={props.engagement.customerName}>
                        {props.engagement.customerName}
                    </ValueWithLabel>
                </Box>
            </Grid>

            {!isGcr && <Grid gridDefinition={[{colspan: 2}, {colspan: 2}]}>
                <Box padding='n' margin='n' variant='p'>
                    <ValueWithLabel label={DASHBOARD_PAGE_TICKETS_DESCRIPTION} value={' '}>
                        <Box padding='n' margin='n' variant='p'>
                            <Link
                                href={TableConstants.AESA_PSQ_LINK(props.engagement)}
                                external={true}
                                data-csm-on='click'
                                data-csm-name={TableConstants.AnalyticsEventType.LinkClick}
                                data-csm-attrs={`engagement_id:${props.engagement.projectId},segment:${props.engagement.segment},element_type:${TableConstants.AnalyticsEvent.AESAPSQTicketsLink}`}
                            >
                                AESA/PSQ Tickets
                            </Link>
                        </Box>
                        <Box padding='n' margin='n' variant='p'>
                            <Link
                                href={TableConstants.DSR_LINK(props.engagement)}
                                external={true}
                                data-csm-on='click'
                                data-csm-name={TableConstants.AnalyticsEventType.LinkClick}
                                data-csm-attrs={`engagement_id:${props.engagement.projectId},segment:${props.engagement.segment},element_type:${TableConstants.AnalyticsEvent.DSRTicketsLink}`}
                            >
                                Deliverable Security Review
                            </Link>
                        </Box>
                        <Box padding='n' margin='n' variant='p'>
                            <Link
                                href={TableConstants.SSP_LINK(props.engagement)}
                                external={true}
                                data-csm-on='click'
                                data-csm-name={TableConstants.AnalyticsEventType.LinkClick}
                                data-csm-attrs={`engagement_id:${props.engagement.projectId},segment:${props.engagement.segment},element_type:${TableConstants.AnalyticsEvent.SSPTicketsLink}`}
                            >
                                Subcontractor Security Package
                            </Link>
                        </Box>
                    </ValueWithLabel>
                </Box>
            </Grid>}
        </ColumnLayout>
    );
};
