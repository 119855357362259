export enum AnalyticsEvent {
    SFDCProjectLink = 'external-link-salesforce',
    SFDCPartnersLink = 'external-link-salesforce-partners',
    PhoneToolLink = 'external-link-phone-tool',
    RGLink = 'external-link-rg',
    DetailPageButton = 'button-detail-page',
    ParentEngagementButton = 'button-parent-engagement',
    UpdateTimezoneButton = 'button-update-timezone',
    AddResourcesButton = 'button-add-resources',
    UpdateResourcesButton = 'button-update-resources',
    RemoveResourcesButton = 'button-remove-resources',
    DeletedCTIRGButton = 'button-delete-ctirg',
    AESAPSQTicketsLink = 'external-link-aesa-psq',
    RGTicketsLink = 'external-link-rg-tickets',
    DSRTicketsLink = 'external-link-dsr',
    SSPTicketsLink = 'external-link-ssr'
}

export enum AnalyticsEventType {
    TabClick = "TAB_CLICK",
    LinkClick = "LINK_CLICK",
    View = "VIEW",
    ButtonClick = "BUTTON_CLICK"
}