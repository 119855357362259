import { StatusIndicator } from '@amzn/awsui-components-react';
import { Engagement } from '../../../core/types';

export interface CTIRGStatusLabelProps {
    engagement: Engagement;
}

export const CTIRGStatusLabel = (props: CTIRGStatusLabelProps) => (
    getStatusLabel(props.engagement.activeCTI, props.engagement.ctiDeletedByAlias)
)

const getStatusLabel = (status?: boolean, deletedBy?: string) => {
    if (status) {
        return <StatusIndicator className='status-indicator' colorOverride='green'>Active</StatusIndicator>
    } else if (deletedBy) {
        return <StatusIndicator className='status-indicator' colorOverride='grey'>Deleted</StatusIndicator>
    } else {
        return <StatusIndicator className='status-indicator' colorOverride='grey'>N/A</StatusIndicator>
    }
}