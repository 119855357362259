import {Modal, Button, SpaceBetween, Box, Link} from '@amzn/awsui-components-react';
import {Engagement} from "../../../core/types";
import {
    isEngagementDeleteable,
    isEngagementDeletionEligible,
    isEngagementInWarningStateForOpenTickets
} from "../../../core/utils/engagement-utils/EngagementUtils";
import * as TableConstants from "../../../core/constants";
import React, {Fragment} from "react";
import {ResourceDeletionModal} from "../resource-deletion-modal";
import {SetState} from "../../../helpers/setStateType";

export type AlertModalProps = {
    engagement: Engagement
    userAlias: string
    closeAction: () => void;
    closeEngagementEnabled: boolean;
    loadData: () => void;
    visible: boolean;
    setVisibility: (visible: boolean) => void;
}

// Returns the list of alerts to display from engagement
function getAlerts(engagement: Engagement, closeEngagementEnabled: boolean, setIsDeletingEngagement: SetState<boolean>,  userAlias: string) {
    const alerts: JSX.Element[] = []

    // padding for <li> alerts
    const defaultLineSpacing = "5px";

    if (isEngagementInWarningStateForOpenTickets(engagement)) {
        const rgLink = TableConstants.RG_Tickets_LINK(engagement.primaryResolverGroup!);

        const alertNumber = alerts.length+1;
        const message = (<li style={{marginBottom: defaultLineSpacing}} key={alertNumber}> <b>{alertNumber}:</b>  This engagement has <b> {engagement.openTickets} Open Resolver Group tickets</b>. All tickets should be resolved when
            an engagement is closed. To review and close your tickets, go to <Link external href={rgLink}>SIM-T</Link>. </li>)
        alerts.push(message);
    }

    if (closeEngagementEnabled && isEngagementDeletionEligible(engagement)) {
        const alertNumber = alerts.length+1;

        const userAllowedToDelete = isEngagementDeleteable(userAlias, engagement);

        // If user can delete engagement, then give them option to and present the button. Otherwise, instruct them to alert an authorized user to do so
        const actionableMessage = userAllowedToDelete? (<Fragment>Click the delete button to start the process.  <br/> <Button data-testid={`delete-ctirg-from-alert-${engagement.id}`} variant="primary" onClick={(event) => {
            setIsDeletingEngagement(true)
        }}>Delete CTI/Resolver Group</Button></Fragment>): 'Contact the engagement manager or one of the engagement leads to delete the CTI/Resolver Group.'

        const message = (<li style={{marginBottom: defaultLineSpacing}} key={alertNumber}> <b>{alertNumber}:</b>  The CTI/Resolver Group should be deleted when the engagement is closed. {actionableMessage}</li>)
        alerts.push(message)
    }
    return alerts
}

function getBodyMessage(alerts: JSX.Element[], engagementName: string): JSX.Element {
    // used to get the correct verb tense (i.e. s or no s at the end)
    const isPlural = alerts.length > 1;

    return (<Fragment>
        This engagement <b>({engagementName}) </b> has {alerts.length} alert{isPlural ? "s" : ""} that require{isPlural ? "" : "s"} attention:  <ul style={{listStyleType: 'none'}}>
        {alerts.map((alert, index) => {return alert})}
    </ul>
    </Fragment>)
}

export const AlertModal = (props: AlertModalProps) => {
    const [isDeletingEngagement, setIsDeletingEngagement] = React.useState(false);

    const alerts = getAlerts(props.engagement, props.closeEngagementEnabled, setIsDeletingEngagement, props.userAlias);
    const title = `Engagement Alerts (${alerts.length})`;
    const message = getBodyMessage(alerts, props.engagement.name);

    const confirmFunction = async () => {
        // Refresh page
        await props.loadData();
    };

    const cancelFunction = async () => {
        props.setVisibility(false);
        setIsDeletingEngagement(false);
    }

    return (<Fragment>
            <Modal
                data-test-id="alert-modal"
                onDismiss={() => props.setVisibility(false)}
                visible={props.visible && !isDeletingEngagement}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button data-testid='modalCancelButton' variant="link"
                                    onClick={() => props.closeAction()}>Close</Button>
                        </SpaceBetween>
                    </Box>
                }
                header={title}
            >
                {message}
            </Modal>

            <ResourceDeletionModal
                data-test-id={"resource-deletion-modal"}
                confirm={confirmFunction}
                cancel={cancelFunction}
                engagement={props.engagement}
                isRenderedAsIcon={false}
                visible={isDeletingEngagement}
                setVisibility={setIsDeletingEngagement}
                modalOnly={true}
                ctiName={`AWS Professional Services → ${props.engagement.segment} → ${props.engagement.primaryResolverGroup}`}
            />
    </Fragment>
    );
}