import { useState } from 'react';
import { Alert, Box, Button, Modal, SpaceBetween } from "@amzn/awsui-components-react";
import { RGMember, RGRole } from "../../../core/types/RGMember";
import { ResolverGroupMemberEditor } from './ResolverGroupMemberEditor';
import { DataAccessService } from '../../../core/api';
import { ModifyResourcesProps } from '../../../core/types/ModifyResourcesProps';
import { AnalyticsEvent, AnalyticsEventType, RG_MEMBER_UPDATE_FLASHBAR_TEXT } from '../../../core/constants';
import { UpdateRgMemberDTO, UpdateResolverGroupRequest } from '../../../core/types/UpdateResolverGroupRequest';
import { AxiosError } from "axios";
import { useAppContext } from '../../../AppContext';
import { Workflows } from "../../../core/workflows";
import { StartWorkflowResponse } from "../../../core/types/StartWorkflowResponse";
import { useEngagementDetailPageContext } from '../../../pages/EngagementDetailPageContext';

export interface AddResourcesProps extends ModifyResourcesProps {
    loading: boolean;
    visible: boolean;
    setVisibility: (visible: boolean) => void;
    setLoading: (loading: boolean) => void;
    segment?: string;
    engagementId: string;
    projectId: string;
    resolverGroup: string;
    engagementSfdcId: string;
    getRgData: () => RGMember[];
    displayRequestError: (errorMessage: string) => void;
}

export const AddResourcesModal = (props: AddResourcesProps) => {

    function updateNewMembers(members: RGMember[], size: number) {
        // Update members
        setNewMembers(members);

        // Update button text
        if (size >= 1) {
            setAddButtonText(`Add ${size} Resource(s)`);
            setAddButtonDisabled(false);
        } else {
            setAddButtonText("Add Resource");
            setAddButtonDisabled(true);
        }
    }

    async function sendNewMembersToESAC() {
        // Verify no data markers are empty
        for (let member of newMembers) {
            if (member.selection_value === "" ||
                member.support_order === undefined) {
                setError(true);
                return;
            }
        }

        const updatedMembers: UpdateRgMemberDTO[] = newMembers.map(member => ({
            alias: member.alias,
            supportOrder: member.support_order,
        }))

        props.setLoading(true);
        // Add members request
        const requestBody: UpdateResolverGroupRequest = {
            engagementId: props.engagementId,
            name: props.resolverGroup,
            updatedMembers,
        };

        try {
            const workflowId = await client.patch(`/ts/rg`, requestBody) as StartWorkflowResponse;
            if (featureFlags["workflow_status"]?.enabled) {
                Workflows.addWorkflow(props.engagementId, workflowId.workflow_id, {add: updatedMembers.length})
                resetWorkflowPollTimeout()
            } else {
                setNotifications([{
                    header: 'Success',
                    content: RG_MEMBER_UPDATE_FLASHBAR_TEXT(updatedMembers.length),
                    type: 'success',
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                }]);
                await props.refreshResources();
            }

            // Clears current members
            setNewMembers([]);
            setToClear(true);
        } catch (e) {
            const error = e as AxiosError
            props.displayRequestError(error.message);
        }

        props.setLoading(false);
        setError(false);
        props.setVisibility(false);
    }

    function resetAfterClearRequest() {
        setToClear(false);
    }

    // State variables
    const [newMembers, setNewMembers] = useState<RGMember[]>([{ alias: "", fullName: "", title: "", role: RGRole.MEMBER, support_order: undefined }]);
    const [addButtonText, setAddButtonText] = useState<string>("Add Resource");
    const [addButtonDisabled, setAddButtonDisabled] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [client, setClient] = useState<DataAccessService>(new DataAccessService());
    const [toClear, setToClear] = useState<boolean>(false);
    const { resetWorkflowPollTimeout } = useEngagementDetailPageContext();
    const { featureFlags } = useAppContext();

    const { setNotifications } = useAppContext();

    return (
        <Modal
            onDismiss={() => props.setVisibility(false)}
            visible={props.visible}
            closeAriaLabel="Close window"
            size="medium"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button id="CancelRgEdit" disabled={props.loading} onClick={(event) => props.setVisibility(false)} variant="link">Cancel</Button>
                        <Button id="SubmitRgEdit"
                            disabled={addButtonDisabled}
                            loading={props.loading}
                            onClick={(event) => sendNewMembersToESAC()}
                            variant="primary"
                            data-csm-on='click'
                            data-csm-name={AnalyticsEventType.ButtonClick}
                            data-csm-attrs={`engagement_id:${props.projectId},segment:${props.segment},element_type:${AnalyticsEvent.AddResourcesButton}`}
                        >
                            {addButtonText}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Add Resources to Resolver Group">
            <Alert visible={error} header="Error" type="error">
                Data fields cannot be empty.
            </Alert>
            <ResolverGroupMemberEditor itemsChanged={updateNewMembers}
                engagementSfdcId={props.engagementSfdcId}
                addToUsedResources={props.addToUsedResources}
                removeFromUsedResources={props.removeFromUsedResources}
                getRemainingResources={props.getRemainingResources}
                resourceCap={10}
                refreshResources={props.refreshResources}
                clearAfterRequest={toClear}
                resetAfterClearRequest={resetAfterClearRequest}
                loading={props.loading} />
        </Modal>
    )
}