import { Icon } from '@amzn/awsui-components-react';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';

export const SuitcaseIcon = () => (
    <Icon
        svg={<span>
            <svg
                height="15"
                width="15"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill={awsui.colorTextInteractiveHover}>
                <path d="M176 56V96H336V56c0-4.4-3.6-8-8-8H184c-4.4 0-8 3.6-8 8zM128 96V56c0-30.9 25.1-56 56-56H328c30.9 0 56 25.1 56 56V96v32V480H128V128 96zM64 96H96V480H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64zM448 480H416V96h32c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64z" />
            </svg>
        </span>}
    />)