//React 
import { Fragment } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { useAmplifyAnalytics } from './core/analytics';
import { config } from './amplifyConfig';
//Pages
import EngagementDashboardPage from './pages/EngagementDashboardPage';
import EngagementDetailPage from './pages/EngagementDetailPage';
import PageNotFound from './pages/PageNotFound';

//Components
import ServiceHeader from './components/ServiceHeader';
import ServiceFooter from './components/ServiceFooter';
import ErrorBoundary from './ErrorBoundary';

import '@amzn/awsui-global-styles/polaris.css';
import { AppContext } from './AppContext';
import { DataAccessService } from './core/api';
import { FlashbarProps } from '@amzn/awsui-components-react';

Amplify.configure({
	Auth: config,
});

function Router() {
	const [user, setUser] = useState(null);
	const [featureFlags, setFeatureFlags] = useState({});
	const [client] = useState(new DataAccessService());
	const [notifications, setNotifications] = useState<any[]>([]);
	const [seeAllEngagementsManagerToggle, setSeeAllEngagementsManagerToggle] = useState<boolean>(false);
	const [seeAllEngagementsResourceToggle, setSeeAllEngagementsResourceToggle] = useState<boolean>(false);
	const { initialize } = useAmplifyAnalytics();
	const appendNotification = (notification: FlashbarProps.MessageDefinition) => {
		setNotifications([...notifications, notification]);
	}

	useEffect(() => {
		async function authUser() {
			await Auth.currentAuthenticatedUser()
				.then((user) => {
					setUser(user);
					const redirectLocation = localStorage.getItem("auth-redirect");
					if (redirectLocation !== null && window.location.pathname !== redirectLocation) {
					  window.location.pathname = redirectLocation;
					}
					localStorage.removeItem('auth-redirect');
				})
				.catch((e) => {
					if (window.location.pathname !== '/') {
					  localStorage.setItem('auth-redirect', window.location.pathname);
					}
					Auth.federatedSignIn();
				});
		}
		authUser();

		// Fetch feature flags
		client.get(`/ts/ff`).then((ffConfig) => {
			const flagsResponse = ffConfig as object;
			setFeatureFlags(flagsResponse);
		});

		// Initialize Pinpoint Metrics
		initialize();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (user != null) {
		return (
			<AppContext.Provider value={{ featureFlags,
											client,
											notifications,
											seeAllEngagementsManagerToggle,
											seeAllEngagementsResourceToggle,
											setSeeAllEngagementsManagerToggle,
											setSeeAllEngagementsResourceToggle,
											setNotifications,
											appendNotification }}>
				<BrowserRouter forceRefresh={false}>
					<ErrorBoundary>
						<Fragment>
							<ServiceHeader />
							<div className='awsui'>
								<Switch>
									<Redirect exact from='/' to='/engagements' />
									<Route exact path='/engagements' component={EngagementDashboardPage} />
									<Route exact path='/engagements/:id' component={EngagementDetailPage} />
									<Route component={PageNotFound} />
								</Switch>
							</div>
							<ServiceFooter />
						</Fragment>
					</ErrorBoundary>
				</BrowserRouter>
			</AppContext.Provider>
		);
	} else {
		return (
			<div className='App'>
				<div className='loader'></div>
			</div>
		)
	}
}

export default Router;
