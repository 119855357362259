import { UserInfo } from "./engagement";

export enum RGRole {
    OWNER = "owner",
    MEMBER = "member"
}

export interface RGMember extends UserInfo {
    alias: string;
    role: RGRole;
    support_order: number | undefined;
    name?: string;
    pager_email?: string;

    // For easy use with the 'Select' components
    selection_label?: string;
    selection_value?: string;
}

export interface EsacRGMember extends UserInfo {
    alias: string;
    role: RGRole;
    support_order: number | undefined;
}