import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ES6Promise from "es6-promise";
import Router from './ts/router';
import './wdyr';
 
ES6Promise.polyfill();

ReactDOM.render(
  <Router />,
  document.getElementById('root')
);