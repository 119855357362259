import { BADGE_PHOTO_LINK } from '../../../core/constants';
import './EmployeePhoto.css';

export interface EmployeePhotoProps {
    alias: string;
    width?: number | string;
    height?: number | string;
}

export const EmployeePhoto = (props: EmployeePhotoProps) => (
    <div className='employee-photo'>
        <img
            style={{
                width: props.width || 40,
                height: props.height || 40
            }}
            alt={`Employee (${props.alias})`}
            src={BADGE_PHOTO_LINK(props.alias)}
        />
    </div>
)