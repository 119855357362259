import { useCollection } from "@amzn/awsui-collection-hooks";
import { Table, TableProps } from "@amzn/awsui-components-react";
import { ReactNode } from "react";
import { ExpandableTableRow } from "./expandable-table-row";
import './ExpandableTable.css';

export interface ExpandableTableProps extends Omit<TableProps, 'columnDefinitions'> {
  getHeaderContent: (any) => ReactNode;
  getExpandedContent: (any) => ReactNode;
  getRowIcon?: (any) => ReactNode;
}

export const ExpandableTable = (props: ExpandableTableProps) => {
  const { items, collectionProps } = useCollection(
    props.items,
    {
      sorting: {}
    }
  );

  return (
    <Table
      {...collectionProps}
      data-testid={props.id}
      header={props.header}
      className='expandable-table'
      loading={props.loading}
      loadingText={props.loadingText}
      items={items}
      empty={props.empty}
      columnDefinitions={[
        {
          id: 'expandable-layout',
          header: '',
          minWidth: '1200px',
          cell: e => <ExpandableTableRow
            rowData={e}
            headerContent={props.getHeaderContent(e)}
            renderExpandedContent={props.getExpandedContent}
            rowIcon={props.getRowIcon ? props.getRowIcon(e) : undefined}
          />
        }
      ]}
    />
  );
};
