// React
import { useState } from 'react';

// Components
import ServiceNavigation from './ServiceNavigation';
import ServiceBreadcrumbs from './ServiceBreadcrumbs';
import ServiceNotifications from './ServiceNotifications';

// Polaris
import { AppLayout, AppLayoutProps } from '@amzn/awsui-components-react';
import { BreadCrumbController } from './ServiceBreadcrumbs';

const DynamicAppLayout = (props) => {
  interface properties extends AppLayoutProps {
    breadcrumbs: any
  }
  const [navigationOpen, setNavigationOpen] = useState(false);
  let defaultProps = {} as properties;

  if (props.page in BreadCrumbController) {
    defaultProps.breadcrumbs = <ServiceBreadcrumbs {...props} />
  }

  Object.assign(defaultProps, {
    navigation: <ServiceNavigation />,
    notifications: <ServiceNotifications />,
    contentType: 'default',
    navigationWidth: 325,
    toolsHide: true,
    disableBodyScroll: true,
    navigationOpen: navigationOpen,
    onNavigationChange: ({ detail }) => setNavigationOpen(detail.open),
    headerSelector: '.header',
    footerSelector: '.footer'
  }, props)

  return (
    <AppLayout {...defaultProps} />
  )
}

// Class ServiceAppLayout is the AppLayout component that is used in the pages.
// Implement like this: <ServiceAppLayout page='GenericNotFound'/>

type IProps = {
  className?: string,
  page: string,
  content: any,
  breadcrumbItems?: any[],
  toolsHide?: boolean,
  disableContentPaddings?: boolean
}

const ServiceAppLayout = (props: IProps) => {
  return (
    <DynamicAppLayout {...props} />
  );
}

export default ServiceAppLayout;