//React
import { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import './WeekdaySelector.scss';

export interface WeekdaySelectorProps {
    data: number[];
    id?: string;
    disabled?: boolean;
    isValid?: boolean;
    setIsValid(value: boolean): void;
}

export const WeekdaySelector = forwardRef((props: WeekdaySelectorProps, _ref) => {
    const labels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const id = props.id || `weekday-selector-${Math.floor(Math.random() * 1000)}`;
    const [weekdayMask, setWeekdayMask] = useState(props.data || [0, 0, 0, 0, 0, 0, 0]);

    const toggleDayMask = (weekdayMask, weekdayIndex) => {
        const newWeekdayMask = [...weekdayMask];
        newWeekdayMask[weekdayIndex] = newWeekdayMask[weekdayIndex] === 0 ? 1 : 0;
        setWeekdayMask(newWeekdayMask);
    }

    useImperativeHandle(_ref, () => weekdayMask);
    useEffect(() => {
        if (props.disabled) {
            setWeekdayMask(props.data);
        }
        props.setIsValid(weekdayMask.includes(1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.disabled, weekdayMask]);
    return (
        <Fragment>
            <div className='weekday-selector'>
                {
                    labels.map((value, index, array) => {
                        return (
                            <div key={index}>
                                <input disabled={props.disabled} type='checkbox' id={`${id}-${index}`}
                                    onClick={() => toggleDayMask(weekdayMask, index)} onChange={() => { }} checked={weekdayMask[index] === 1} />
                                <label htmlFor={`${id}-${index}`}>{value}</label>
                            </div>

                        )
                    })
                }
            </div>
            {!props.isValid && <div className='invalid-selection'>Invalid entry. Must select at least one day.</div>}
        </Fragment>
    );
});