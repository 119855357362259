import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DASHBOARD_PAGE_LINK, DASHBOARD_PAGE_NAME } from '../core/constants';


const BreadCrumbController = {
  PageNotFound: [
    {
      text: DASHBOARD_PAGE_NAME,
      href: DASHBOARD_PAGE_LINK,
    },
    {
      text: 'Page Not Found',
      href: '/404',
    },
  ],
  EngagementDashboardPage: [
    {
      text: DASHBOARD_PAGE_NAME,
      href: DASHBOARD_PAGE_LINK,
    }
  ],
  EngagementDetailPage: [
    {
      text: DASHBOARD_PAGE_NAME,
      href: DASHBOARD_PAGE_LINK,
    }
  ],
};

interface IProps {
  history: any;
  page: any;
  breadcrumbItems: any;
}

const ServiceBreadcrumbs = (props: RouteComponentProps<IProps>) => {
  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      props.history.push(ev.detail.href);
    }
  };

  var crumbs =
    props.page in BreadCrumbController ? BreadCrumbController[props.page] : [];

  if ('breadcrumbItems' in props) {
    crumbs = crumbs.concat(props.breadcrumbItems);
  }

  return (
    <BreadcrumbGroup
      ariaLabel='Breadcrumbs'
      onFollow={onFollowHandler}
      items={crumbs}
    />
  );
};

export default withRouter(ServiceBreadcrumbs);
export { BreadCrumbController };
