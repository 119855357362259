//React
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

// Polaris
import { TimeInput } from '@amzn/awsui-components-react';
import './TimeSelector.scss';
import { is12HourTime } from '../../../helpers/utils';
import { MODE } from '../../../core/constants';

export interface TimeSelectorProps {
    time: string;
    id?: string;
    mode: MODE;
    isValid: boolean;
    setIsValid: (isValid: boolean) => void;
}

export const TimeSelector = forwardRef((props: TimeSelectorProps, _ref) => {
    const time = props.time.split(' ');
    const id = props.id || `time-selector-${Math.floor(Math.random() * 1000)}`;
    const [value, setValue] = useState(time[0] ? time[0] : '00:00');
    const [timeOfDay, setTimeOfDay] = useState(time[1] ? time[1] : 'AM');


    
    function handleChange({detail}: any) {
        const newValue = detail.value;
        setValue(newValue || '00:00');
    }

    useImperativeHandle(_ref, () => ({ value, timeOfDay }));

    useEffect(() => {
        props.setIsValid(is12HourTime(value));
    }, [props, value])

    return (
        <div>
            <div className='time-selector'>
                <TimeInput
                    onChange={handleChange}
                    value={value}
                    format='hh:mm'
                    placeholder='hh:mm'
                    invalid={!props.isValid}
                />
                <div className='radio-selector'>
                    <input type='radio' id={`${id}-AM`} value='AM'
                        onClick={(value) => setTimeOfDay('AM')} onChange={() => { }} checked={timeOfDay === 'AM'} />
                    <label htmlFor={`${id}-AM`}>AM</label>

                    <input type='radio' id={`${id}-PM`} value='PM'
                        onClick={(value) => setTimeOfDay('PM')} onChange={() => { }} checked={timeOfDay === 'PM'} />
                    <label htmlFor={`${id}-PM`}>PM</label>
                </div>
            </div>
            {!props.isValid && <div className='invalid-time'>Invalid entry. 12 hour time format.</div>}
        </div>
    );
});

