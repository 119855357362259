//React
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// Components
import ServiceAppLayout from '../components/ServiceAppLayout';
import { Grid, Container, Button, SpaceBetween } from '@amzn/awsui-components-react';
import { InternalLink } from '../components/shared';
import { DASHBOARD_PAGE_LINK, DASHBOARD_PAGE_NAME } from '../core/constants';

const PageNotFound = (props: RouteComponentProps) => {
  return (
    <ServiceAppLayout
      page='PageNotFound'
      content={<Content />}
    />
  );
}

// The content in the main content area of the App layout
const Content = () => (
  <Container>
    <Grid className='generic-not-found'
      gridDefinition={[
        { colspan: { xxs: 12 } },
        { colspan: { xxs: 12 } },
        { colspan: { xxs: 12 } }]}
    >
      <div className='generic-not-found__content  align-center'>
        <div className='generic-not-found__header'>
          <h1>404</h1>
        </div>
      </div>
      <div className='generic-not-found__content align-center'>
        <div className='align-center color-primary'>
          <h1>Sorry, this URL does not exist or is no longer available.</h1>
        </div>
      </div>
      <div className='generic-not-found__content align-center'>
        <h2>Perhaps you were looking for one of the following sections:</h2>
        <SpaceBetween direction='vertical' size='xs'>
          <InternalLink href={DASHBOARD_PAGE_LINK}><Button>{DASHBOARD_PAGE_NAME}</Button></InternalLink>
        </SpaceBetween>
      </div>
    </Grid>
  </Container>
);

export default withRouter(PageNotFound);