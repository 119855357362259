import React from 'react';


import { Grid, Container } from '@amzn/awsui-components-react/polaris';
class ErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Grid className="generic-not-found"
          gridDefinition={[
            { colspan: { default: 12 } },]}
        >
          <Container>
            <div className="generic-not-found__content align-center">
              <div className="align-center color-primary">
                <h1>A fatal error has occurred</h1>
              </div>
            </div>
          </Container>
        </Grid>
      );
    }

    return this.props.children;
  }


}

export default ErrorBoundary;