import { Engagement } from "../../types"
import {
    filterForActiveEngagements,
    isEngagementActive,
    isEngagementDeletionEligible
} from "./EngagementUtils";


export class EngagementSort {
    /**
     * Sorts engagements in the following order:
     * active -> closed but deleteable -> all other
     * 
     * Returns - 0 if engagements are equal
     *           -1 if 'a' should be placed before 'b'
     *           1 if 'b' should be placed before 'a'
     * 
     * @param {Engagement} a - First engagement to compare
     * @param {Engagement} b - Second engagement to compare
     */
    public sortEngagements(a: Engagement, b: Engagement): number {
        // Shouldn't happen - but we don't require stage from salesforce so it is possible
        if (!a.stage && !b.stage) {
            return 0;
        } else if (!a.stage && b.stage) {
            return 1;
        } else if (a.stage && !b.stage) {
            return -1;
        }
    
        // Keep active engagements before anything else
        const activeA: boolean = isEngagementActive(a);
        const activeB: boolean = isEngagementActive(b);
        if (activeA && activeB) {
            return 0;
        } else if (activeA && !activeB) {
            return -1;
        } else if (!activeA && activeB) {
            return 1;
        } else {
            // Both are not active - deletion eligible will be next in table
            const deletionA: boolean = isEngagementDeletionEligible(a);
            const deletionB: boolean = isEngagementDeletionEligible(b);
            if (deletionA === deletionB) {
                return 0;
            } else if (deletionA && !deletionB) {
                return -1;
            } else {
                return 1;
            }
        }
    }

    // TODO: Remove this method once EHI Active feature has been approved.
    // [Flag - ff-frontend-ehi-active-20230221]
    // Before doing so, replace instances of isEngagementActive with filterForActiveEngagements in sortEngagements.
    public sortEngagementsEhiActiveFFEnabled(a: Engagement, b: Engagement): number {
        // Shouldn't happen - but we don't require stage from salesforce so it is possible
        if (!a.stage && !b.stage) {
            return 0;
        } else if (!a.stage && b.stage) {
            return 1;
        } else if (a.stage && !b.stage) {
            return -1;
        }

        // Keep active engagements before anything else
        const activeA: boolean = filterForActiveEngagements(a);
        const activeB: boolean = filterForActiveEngagements(b);
        if (activeA && activeB) {
            return 0;
        } else if (activeA && !activeB) {
            return -1;
        } else if (!activeA && activeB) {
            return 1;
        } else {
            // Both are not active - deletion eligible will be next in table
            const deletionA: boolean = isEngagementDeletionEligible(a);
            const deletionB: boolean = isEngagementDeletionEligible(b);
            if (deletionA === deletionB) {
                return 0;
            } else if (deletionA && !deletionB) {
                return -1;
            } else {
                return 1;
            }
        }
    }
}
